interface ExtendedNavigatorType extends Navigator {
  userAgentData?: {
    brands: Array<{
      brand: string;
    }>;
  };
}

interface ExtendedWindowType extends Window {
  chrome?: object;
  navigator: ExtendedNavigatorType;
}

export const isInChrome = () => {
  const extendedWindow = window as ExtendedWindowType;
  const isIOSChrome = extendedWindow.navigator.userAgent.match("CriOS");
  const isGoogleChrome =
    extendedWindow.chrome &&
    (extendedWindow.navigator.userAgentData?.brands[0].brand === "Google Chrome" ||
      extendedWindow.navigator.vendor === "Google Inc.");

  return isIOSChrome || isGoogleChrome;
};
