import type { Optional } from "types/utils";

/**
 * Determines is a string is only whitespace
 * @param {string} str The string
 */
//https://stackoverflow.com/questions/10261986/how-to-detect-string-which-contains-only-spaces/50971250
export const isOnlyWhitespace = (str: string): boolean => {
  return str.replace(/\s/g, "").length === 0;
};

/**
 * Truncates a string that surpasses a certain max length and adds ellipses
 */
export function truncateString(inputString: string, maxLength: number) {
  if (inputString.length > maxLength) {
    return inputString.substring(0, maxLength) + "...";
  } else {
    return inputString;
  }
}

/**
 * Truncates an optional string that surpasses a certain max length and adds ellipses
 */
export function truncateStringOpt(inputString: Optional<string>, maxLength: number) {
  if (!inputString) return inputString;
  return truncateString(inputString, maxLength);
}

/**
 * Stringifies a value to JSON with readable spacing
 * @param val The value to stringify
 * @returns
 */
export function JSONStringifyPretty(val: unknown): string {
  return JSON.stringify(val, null, 2);
}

/**
 * Converts a string to title case
 * @param str
 * @returns
 */
//https://stackoverflow.com/questions/196972/convert-string-to-title-case-with-javascript
export function toTitleCase(str: string) {
  return str.replace(
    /\w\S*/g,
    (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}
