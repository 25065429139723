import { ErrorBoundary } from "common_ui/ErrorBoundary";
import { memo, useCallback, useEffect } from "react";
import UnhandledErrorContextWrapper from "./CrashHandlerContext";
import { CrashHandlerNotice } from "./CrashHandlerNotice";
import { ActionIcon, AppShell, Group, Header } from "@mantine/core";
import { ColorModeSwitcher } from "common_ui/ColorModeSwitcher";
import { FiHome } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { UserCoordinatesDisplayer } from "common_ui/UserCoordinatesDisplayer";

const DEFAULT_TAB_TITLE = "There to Where";

export const TAB_NAME_BASE = `${DEFAULT_TAB_TITLE} | `;

export const makePage = <T extends JSX.IntrinsicAttributes>(
  Component: React.ComponentType<T>,
  tabTitle?: string,
  config?: { hideLocationUI: boolean }
) => {
  const ComponentWrapped = (props: T) => {
    const navigate = useNavigate();

    useEffect(() => {
      document.title = tabTitle ?? DEFAULT_TAB_TITLE;
    }, []);

    const goHome = useCallback(() => {
      navigate("/");
    }, [navigate]);

    const header = (
      <Header height={40}>
        <Group className="h100 px-2 flex-nowrap">
          <ColorModeSwitcher />
          <ActionIcon onClick={goHome} variant="outline" color="blue">
            <FiHome />
          </ActionIcon>
          <div className="flex-1" />
          {!config?.hideLocationUI && <UserCoordinatesDisplayer allowEdit />}
        </Group>
      </Header>
    );

    return (
      <UnhandledErrorContextWrapper>
        <CrashHandlerNotice />
        <ErrorBoundary>
          <AppShell
            header={header}
            padding={0}
            styles={{ body: { width: "100vw", height: "100vh" } }}
          >
            <Component {...props} />
          </AppShell>
        </ErrorBoundary>
      </UnhandledErrorContextWrapper>
    );
  };

  return memo(ComponentWrapped);
};
