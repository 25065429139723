import { Box, Button, Space, Text, Title, Tooltip } from "@mantine/core";
import { useForm } from "@mantine/form";
import { Analytics } from "analytics/webAnalytics";
import { CloudFunctions } from "apis/internal/cloudFunctions";
import { ErrorBoundary } from "common_ui/ErrorBoundary";
import { FloatingActionButton } from "common_ui/FloatingActionButton";
import { LoadingModal } from "common_ui/LoadingModal";
import { StandardEmptyState } from "common_ui/StandardEmptyState";
import { StandardPanelResizeHandle } from "common_ui/StandardPanelResizeHandle";
import { Feature } from "ol";
import { Point as OlPoint } from "ol/geom";
import { fromLonLat } from "ol/proj";
import { makePage, TAB_NAME_BASE } from "page_setup/makePage";
import { useSmallScreen } from "page_setup/ScreenSizeContext";
import type { HeatmapConfigurationPageNavigationArgs } from "pages/heatmap_configuration/types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FiList, FiMap, FiMapPin } from "react-icons/fi";
import { Panel, PanelGroup } from "react-resizable-panels";
import { useLocation, useNavigate } from "react-router-dom";
import type { Coordinates } from "types/geo";
import {
  type GroupID,
  type HeatmapRequest,
  HeatmapTransportationMode,
} from "types/internal_apis/get_heatmap";
import type { Nullable, Optional } from "types/utils";
import {
  arrayPickRandom,
  arrayRemoveFirstStrict,
  arrayReplaceFirstStrict,
  coalesceEmptyArray,
} from "utils/arrays";
import { useUserCoordinates } from "utils/geo";
import { useToggleState } from "utils/hooks";
import { assertDefined } from "utils/misc";
import { centerMapAroundVectorSource } from "utils/openLayers/commonOperations";
import { v4 as uuid } from "uuid";
import { validateGroup } from "./groupValidation";
import { GroupListElement } from "./HeatmapPointChooserGroupListElement";
import { type HeatmapPointChooserPoint, usePointChooserMap } from "./HeatmapPointChooserMap";
import type { HeatmapPointChooserPageNavigationArgs, PartialGroup } from "./types";

import "./HeatmapPointChooser.css";
import { STANDARD_COLOR_SWATCHES } from "common_ui/CompactColorInput";
import { PersistentMapAnchor } from "common_ui/openlayers_specific/PersistentMapAnchor";

//TODO: these should eventually be based on the user location
// For example, public transportation is not really usable everywhere in the states
const NEW_GROUP_TRANSPORTATION_MODE = HeatmapTransportationMode.PUBLIC_TRANSPORTATION;
const NEW_GROUP_MAX_BUCKET_IN_S = 30 * 60; //30 minutes

const HeatmapPointChooserImpl = () => {
  const location = useLocation();
  const locationState = location.state as Optional<HeatmapPointChooserPageNavigationArgs>;
  const [groups, setGroups] = useState<PartialGroup[]>(
    coalesceEmptyArray(locationState?.initialGroups)
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const userCoordinates = useRef<Nullable<Coordinates>>(null);
  const navigate = useNavigate();
  const userLocation = useUserCoordinates();

  const { logEvent } = Analytics.useAnalytics();

  const form = useForm({
    mode: "controlled",
  });

  const smallScreen = useSmallScreen();
  const [showingConfigurationSection, toggleShowingConfigurationSection] = useToggleState(true);

  const { mapRef, pointSourceRef } = usePointChooserMap();

  const updateFormValidation = useCallback(
    (group: PartialGroup) => {
      const validationError = validateGroup(group);
      if (!validationError) form.clearFieldError(group.id);
      else form.setFieldError(group.id, validationError);
    },
    [form]
  );

  const errorMessage = useMemo(() => {
    if (groups.length === 0) {
      return "No criteria entered!";
    }
    if (Object.keys(form.errors).length !== 0) {
      return "One or more criteria are invalid!";
    }
    return null;
  }, [form.errors, groups.length]);

  const addPlaceGroup = useCallback(() => {
    const newGroup: PartialGroup = {
      id: uuid(),
      mode: NEW_GROUP_TRANSPORTATION_MODE,
      max_bucket_in_s: NEW_GROUP_MAX_BUCKET_IN_S,
      name: `Criterion ${groups.length + 1}`,
      color: arrayPickRandom(STANDARD_COLOR_SWATCHES),
      places: [],
    };
    updateFormValidation(newGroup);
    setGroups([newGroup, ...groups]);
  }, [groups, updateFormValidation]);

  const onEditPlaceGroup = useCallback(
    (editedGroup: PartialGroup) => {
      updateFormValidation(editedGroup);
      setGroups(arrayReplaceFirstStrict(groups, (x) => x.id === editedGroup.id, editedGroup));
    },
    [groups, updateFormValidation]
  );

  const onDeletePlaceGroup = useCallback(
    (group: PartialGroup) => {
      form.clearFieldError(group.id);
      setGroups(arrayRemoveFirstStrict(groups, (x) => x.id === group.id));
    },
    [form, groups]
  );

  const getHeatmap = useCallback(async () => {
    const args = { groups: [], places: [] } as HeatmapRequest;
    for (const group of groups) {
      args.groups.push({
        id: group.id,
        max_bucket_in_s: assertDefined(group.max_bucket_in_s),
        transportation_mode: group.mode,
        name: group.name,
      });

      for (const place of group.places) {
        args.places.push({
          name: assertDefined(place.name),
          points: assertDefined(place.points?.map((coord) => [coord.longitude, coord.latitude])),
          group_id: group.id,
          id: place.id,
        });
      }
    }
    setIsLoading(true);

    logEvent("requested_heatmap", {
      numberOfPlaces: args.places.length,
      numberOfGroups: args.groups.length,
    });

    const timeBeforeCall = Date.now();
    const heatmap = await CloudFunctions.ApiGetHeatmap(args);
    const timeAfterCall = Date.now();

    logEvent("received_requested_heatmap", {
      timeTakenInMs: timeAfterCall - timeBeforeCall,
      numberOfParts: heatmap.feature_collection.features.length,
      numberOfPlaces: Object.keys(heatmap.places).length,
      numberOfGroups: Object.keys(heatmap.groups).length,
    });

    const groupMetadata: HeatmapConfigurationPageNavigationArgs["groupMetadata"] = {};
    groups.forEach((group) => {
      groupMetadata[group.id as GroupID] = {
        color: group.color,
        hidePoints: group.hidePoints,
      };
    });

    const locationState: HeatmapConfigurationPageNavigationArgs = {
      heatmap: heatmap,
      groupMetadata,
      id: uuid(),
      creationTimestamp: Date.now(),
    };
    navigate("/heatmap/view", { state: locationState });
  }, [groups, logEvent, navigate]);

  useEffect(() => {
    if (userLocation) {
      userCoordinates.current = userLocation;
      if (pointSourceRef.current?.isEmpty()) {
        mapRef.current
          ?.getView()
          .setCenter(fromLonLat([userLocation.longitude, userLocation.latitude]));
      }
    }
  }, [mapRef, pointSourceRef, userLocation]);

  const lastSeenPointCount = useRef(pointSourceRef.current?.getFeatures().length ?? 0);
  useEffect(() => {
    if (!pointSourceRef?.current) return;
    const addedFeatures = [];
    pointSourceRef.current.clear();
    for (const group of groups) {
      for (const place of group.places) {
        for (const point of coalesceEmptyArray(place.points)) {
          const properties: HeatmapPointChooserPoint = {
            geometry: new OlPoint(fromLonLat([point.longitude, point.latitude])),
            name: place.name,
            color: group.color,
            hidden: group.hidePoints,
          };
          addedFeatures.push(new Feature(properties));
        }
      }
    }

    if (addedFeatures.length) {
      pointSourceRef.current?.addFeatures(addedFeatures);
    }

    if (lastSeenPointCount.current !== addedFeatures.length) {
      if (!pointSourceRef.current.isEmpty()) {
        centerMapAroundVectorSource(pointSourceRef.current, mapRef.current);
      } else if (userCoordinates.current !== null) {
        const point = userCoordinates.current;
        mapRef.current?.getView().setCenter(fromLonLat([point.longitude, point.latitude]));
      }
    }

    lastSeenPointCount.current = addedFeatures.length;
  }, [groups, mapRef, pointSourceRef]);

  const configurationSection = (
    <div className="col w100 h100">
      <LoadingModal open={isLoading} />
      <div className="col heatmap-creation-sidepanel-parent">
        <Title order={smallScreen ? 2 : 1}>Let's make your heatmap</Title>
        <Text>
          Add your criteria for an ideal living situation, and we'll show you which parts of your
          city best fit those preferences
        </Text>
        <Space h="sm" />
        <div className="button-panel">
          <Button onClick={addPlaceGroup}>Add Criteria</Button>
          <Tooltip label={errorMessage} withArrow disabled={!errorMessage}>
            {/* Need to wrap in div since disabled button gets no hover events */}
            <div>
              <Button
                onClick={getHeatmap}
                disabled={!!errorMessage}
                variant="gradient"
                gradient={{ from: "violet", to: "grape", deg: 60 }}
              >
                Get Heatmap
              </Button>
            </div>
          </Tooltip>
        </div>

        {groups.length === 0 && (
          <div className="w100 flex-1">
            <StandardEmptyState
              message="Add a criteria to start adding your preferences."
              icon={<FiMapPin />}
            />
          </div>
        )}

        {groups.map((g) => (
          <GroupListElement
            group={g}
            key={g.id}
            onGroupEdit={onEditPlaceGroup}
            onDeleteDelete={onDeletePlaceGroup}
          />
        ))}
      </div>
    </div>
  );

  const mapSection = <PersistentMapAnchor className="w-100 h-full" map={mapRef.current} />;

  // We're using this strange pattern (using visibility: hidden instead of display: none) since the map
  // needs to have an actual size in the DOM for certain operations to work properly
  if (smallScreen) {
    return (
      <Box className="w100 h100">
        {showingConfigurationSection ? (
          <>
            <div key="config" style={{ display: "contents" }}>
              {configurationSection}
            </div>
            <div key="map" style={{ visibility: "hidden" }}>
              {mapSection}
            </div>
          </>
        ) : (
          <>
            <div key="config" style={{ display: "none" }}>
              {configurationSection}
            </div>
            <div key="map" style={{ display: "contents" }}>
              {mapSection}
            </div>
          </>
        )}
        {!isLoading && (
          <FloatingActionButton
            icon={showingConfigurationSection ? <FiMap /> : <FiList />}
            onClick={toggleShowingConfigurationSection}
          />
        )}
      </Box>
    );
  } else {
    return (
      <ErrorBoundary>
        <PanelGroup autoSaveId="heatmapPointChooserPanelGroup" direction="horizontal">
          <Panel>{configurationSection}</Panel>
          <StandardPanelResizeHandle />
          <Panel maxSize={80} minSize={20}>
            {mapSection}
          </Panel>
        </PanelGroup>
      </ErrorBoundary>
    );
  }
};

export const HeatmapPointChooser = makePage(
  HeatmapPointChooserImpl,
  TAB_NAME_BASE + "Heatmap Maker"
);
