import type { Optional, Undefined } from "types/utils";
import { getEnvVariable } from "./env";

export function noop() {
  return null;
}
export function isInDevMode(): boolean {
  // NODE_ENV check is enough if just using npm run start, but hostname check needed if using firebase hosting emulator
  return (
    getEnvVariable("NODE_ENV") === "development" ||
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  );
}

/**
 * A way to console.log with some nice formatting options
 * @param {*} msg The message
 * @param {*} style {textColor, backgroundColor, fontSize}
 */
interface prettyLogArgs {
  textColor?: string;
  backgroundColor?: string;
  fontSize?: number;
}
export function prettyLog(msg: any, style?: prettyLogArgs): void {
  let styleString = "";
  if (style?.textColor) {
    styleString += `color: ${style.textColor};`;
  }
  if (style?.backgroundColor) {
    styleString += `background: ${style.backgroundColor};`;
  }
  if (style?.fontSize) {
    styleString += `font-size: ${style.fontSize}px;`;
  }
  console.log("%c%s", styleString, msg);
}

/**
 * Standard alert to show when something's not ready for use yet (or is broken due to new developments)
 */
export function ShowNotSupportedAlert(): void {
  alert("Not yet, young whippersnapper! This ins't done yet");
}

/**
 * Returns an set of keys that are in A but not in B
 * @param objA Object A
 * @param objB Object B
 */
export function objectDifference(
  objA: Record<string, unknown>,
  objB: Record<string, unknown>
): Set<string> {
  const setA = new Set(Object.keys(objA));
  const setB = new Set(Object.keys(objB));
  return new Set([...setA].filter((x) => !setB.has(x)));
}

//Some discussion on why this isn't something built into type script:
//https://stackoverflow.com/questions/62053739/preserve-type-when-using-object-entries
//https://stackoverflow.com/questions/57390305/trying-to-get-fromentries-type-right
export function recordEntries<K extends string, V>(record: Record<K, V>): Array<[K, V]> {
  return Object.entries(record) as any;
}

export function objectKeyFromValue<K extends string, V>(
  record: Record<K, V>,
  value: V
): Undefined<K> {
  const entries = recordEntries(record);
  const foundEntry = entries.find((x) => x[1] === value);
  return foundEntry?.[0];
}

export function coalesceEmptyObject<K extends string, V>(
  val: Optional<Record<K, V>>
): Record<K, V> {
  return val ?? ({} as Record<K, V>);
}

export function assertDefined<T>(value: Undefined<T>): T {
  if (value === undefined) throw new Error("Unexpected undefined value!");
  return value;
}

export function hasValue<T>(value: Optional<T>): value is T {
  return value !== undefined && value !== null;
}
