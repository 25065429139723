import * as ReactDOM from "react-dom/client";
import { DevHeatmapConfiguration } from "pages/_dev_pages/DevHeatmapConfiguration";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { DevIsochroneTester } from "./pages/_dev_pages/DevIsochroneTester";
import { HeatmapPointChooser } from "./pages/heatmap_point_chooser/HeatmapPointChooser";
import { DistanceMatrixPage } from "./pages/distance_matrix/DistanceMatrixPage";
import ScreenSizeContextWrapper from "./page_setup/ScreenSizeContext";
import { ThemeProvider } from "./styling/themeProvider";
import { isInDevMode } from "./utils/misc";
import { HeatmapConfiguration } from "pages/heatmap_configuration/HeatmapConfiguration";
import { DevPointGetter } from "pages/_dev_pages/DevPointGetter";
import { LandingPage } from "pages/landing_page/LandingPage";
import { NoPageFoundPage } from "pages/404_page/NoPageFoundPage";
import { getEnvVariable } from "utils/env";
import { initializeApp } from "firebase/app";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { EmbeddedHeatmapPage } from "pages/embedded_heatmap/EmbeddedHeatmapPage";
import { HeatmapListPage } from "pages/heatmap_list/HeatmapListPage";
import { PrivacyPolicyPage } from "pages/privacy_policy/PrivacyPolicyPage";

import "./styling/globalStyles.css";
import { BrowserExtensionPage } from "pages/browser_extension_page/BrowserExtensionPage";

initializeApp(JSON.parse(getEnvVariable("REACT_APP_PROD_FIREBASE_CONFIG")));

// Thankfully, Posthog also records the web vitals
// More about this config can be found here: https://posthog.com/docs/libraries/js#config
// https://posthog.com/tutorials/multiple-environments
// https://posthog.com/docs/product-analytics/autocapture
posthog.init(
  isInDevMode()
    ? getEnvVariable("REACT_APP_PUBLIC_POSTHOG_DEV_KEY")
    : getEnvVariable("REACT_APP_PUBLIC_POSTHOG_PROD_KEY"),
  {
    api_host: getEnvVariable("REACT_APP_PUBLIC_POSTHOG_HOST"),
    autocapture: true,
    loaded: (ph) => {
      if (isInDevMode()) {
        ph.set_config({ disable_session_recording: true, debug: true });
      }
    },
  }
);

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

root.render(
  <PostHogProvider client={posthog}>
    <ScreenSizeContextWrapper>
      <ThemeProvider>
        <BrowserRouter>
          <Routes>
            <Route path="" element={<LandingPage />} />

            <Route path="distance-matrix" element={<DistanceMatrixPage />} />

            <Route path="heatmap/view" element={<HeatmapConfiguration />} />
            <Route path="heatmap/create" element={<HeatmapPointChooser />} />
            <Route path="heatmap/list" element={<HeatmapListPage />} />
            <Route path="heatmap/embed" element={<EmbeddedHeatmapPage />} />

            <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="we-got-an-extension-babyyyy" element={<BrowserExtensionPage />} />

            {isInDevMode() && (
              <>
                <Route path="dev/isochrones" element={<DevIsochroneTester />} />
                <Route path="dev/heatmap" element={<DevHeatmapConfiguration />} />
                <Route path="dev/points" element={<DevPointGetter />} />
              </>
            )}

            <Route path="*" element={<NoPageFoundPage />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </ScreenSizeContextWrapper>
  </PostHogProvider>
);
