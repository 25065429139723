import { PersistentMapAnchor } from "common_ui/openlayers_specific/PersistentMapAnchor";
import { fromLonLat } from "ol/proj";
import { memo, useEffect, useRef } from "react";
import type { StoredHeatmap } from "types/heatmap_storage/storedHeatmap";
import type { Heatmap } from "types/internal_apis/get_heatmap";
import { useHeatmapPolygonStyling } from "utils/heatmap/heatmapParts";
import { useEmbeddedHeatmapMap } from "./EmbeddedHeatmapMap";

interface EmbeddedHeatmapProps {
  heatmap: StoredHeatmap;
  lat: number;
  long: number;
  zoom: number;
}

const EmbeddedHeatmapInnerImpl = (props: EmbeddedHeatmapProps) => {
  const heatmapRef = useRef<Heatmap>(props.heatmap.heatmap);
  const groupMetadataRef = useRef(props.heatmap.groupMetadata);

  const { refreshHeatmapPartScores, polygonStyleFunction } = useHeatmapPolygonStyling(
    heatmapRef,
    groupMetadataRef
  );

  const { mapRef, polygonLayer } = useEmbeddedHeatmapMap(polygonStyleFunction, heatmapRef.current);

  // Called on mount
  useEffect(() => {
    refreshHeatmapPartScores();
    polygonLayer?.changed();
  }, [polygonLayer, refreshHeatmapPartScores]);

  useEffect(() => {
    mapRef.current?.getView().setCenter(fromLonLat([props.long, props.lat]));
    mapRef.current?.getView().setZoom(props.zoom);
  }, [mapRef, props.lat, props.long, props.zoom]);

  return <PersistentMapAnchor map={mapRef.current} className="w-100 h-full" />;
};

export const EmbeddedHeatmapInner = memo(EmbeddedHeatmapInnerImpl);
