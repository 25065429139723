import type { Nullable } from "types/utils";

export const roundToSigFigs = (n: number, sigFigs: number) => {
  const baseNumber = n.toPrecision(sigFigs);
  // https://stackoverflow.com/questions/24295121/remove-leading-and-trailing-zeros-from-a-string
  return baseNumber.replace(/^0+(\d)|(\d)0+$/gm, "$1$2");
};

export const clamp = (num: number, min: number, max: number): number =>
  Math.max(min, Math.min(max, num));

export const safeFloat = (value: any): Nullable<number> => {
  const conversion = parseFloat(value);
  if (isNaN(conversion)) return null;
  return conversion;
};

export const safeInt = (value: any): Nullable<number> => {
  const conversion = parseInt(value);
  if (isNaN(conversion)) return null;
  return conversion;
};
