import { Container, Title, Divider, List, Anchor, Text } from "@mantine/core";
import { makePage, TAB_NAME_BASE } from "page_setup/makePage";
import { botProtectEmail } from "utils/emails";
import { getEnvVariable } from "utils/env";

const PrivacyPolicyPageImpl = () => {
  const developerName = getEnvVariable("REACT_APP_DEVELOPER_NAME");
  const email = getEnvVariable("REACT_APP_PRIVACY_POLICY_EMAIL");
  const formattedEmail = botProtectEmail(email);

  return (
    <Container size="md" p="md">
      <Title order={2} align="center" mb="lg">
        Privacy Policy
      </Title>

      <Text>
        This privacy policy applies to the There To Where website (hereby referred to as
        "Application") created by {developerName} (hereby referred to as "Service Provider"). This
        service is intended for use "AS IS".
      </Text>

      <Divider my="lg" />

      <Title order={3} mt="lg">
        Information Collection and Use
      </Title>
      <Text>
        The Application collects information when you use it. This information may include:
      </Text>
      <List withPadding>
        <List.Item>Your device's Internet Protocol address (e.g., IP address)</List.Item>
        <List.Item>
          The pages of the Application that you visit, the time and date of your visit, the time
          spent on those pages, and your activity on those pages (like button clicks)
        </List.Item>
        <List.Item>The time spent on the Application</List.Item>
        <List.Item>The operating system you use</List.Item>
        <List.Item>Your location when you use the Application</List.Item>
      </List>
      <Text mt="md">
        All of this information is collected automatically and is only used to understand how the
        Application is being used. We do not sell your data to any third parties.
      </Text>

      <Text mt="md">
        The Service Provider may use the information you provide to contact you from time to time
        with important information, required notices, and marketing promotions.
      </Text>

      <Text mt="md">
        For a better experience while using the Application, the Service Provider may require you to
        provide certain personally identifiable information. The information requested will be
        retained and used as described in this privacy policy.
      </Text>

      <Divider my="lg" />

      <Title order={3} mt="lg">
        Third Party Services/Access
      </Title>
      <Text>
        These are the third-party services that are used to gather or process your data. Their
        privacy policies apply as well:
      </Text>
      <List withPadding>
        <List.Item>
          <Anchor href="https://posthog.com/privacy" target="_blank" rel="noopener noreferrer">
            PostHog
          </Anchor>
        </List.Item>
        <List.Item>
          <Anchor
            href="https://cloud.google.com/terms/cloud-privacy-notice"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Cloud Platform
          </Anchor>
        </List.Item>
      </List>
      <Text mt="md">
        The Service Provider may disclose User Provided and Automatically Collected Information:
      </Text>
      <List withPadding>
        <List.Item>
          As required by law, such as to comply with a subpoena or similar legal process.
        </List.Item>
        <List.Item>
          When they believe in good faith that disclosure is necessary to protect their rights,
          protect your safety or the safety of others, investigate fraud, or respond to a government
          request.
        </List.Item>
        <List.Item>
          With trusted service providers who work on their behalf, do not have independent use of
          the information disclosed, and adhere to this privacy policy.
        </List.Item>
      </List>

      <Divider my="lg" />

      <Title order={3} mt="lg">
        Data Retention Policy
      </Title>
      <Text>
        The Service Provider will retain User Provided Data for as long as you use the Application
        and for a reasonable time thereafter. If you'd like the data to be deleted, please contact
        them at <Anchor href={`mailto:${email}`}>{formattedEmail}</Anchor>, and they will respond
        within a reasonable time.
      </Text>

      <Divider my="lg" />

      <Title order={3} mt="lg">
        Children
      </Title>
      <Text>
        The Service Provider does not knowingly solicit data from or market to children under the
        age of 13.
      </Text>
      <Text mt="md">
        If a child under 13 provides personal information, the Service Provider will immediately
        delete it from their servers. If you are a parent or guardian and are aware that your child
        has provided personal information, please contact the Service Provider at{" "}
        <Anchor href={`mailto:${email}`}>{formattedEmail}</Anchor>.
      </Text>

      <Divider my="lg" />

      <Title order={3} mt="lg">
        Changes
      </Title>
      <Text>
        This Privacy Policy may be updated from time to time. You are advised to consult it
        regularly for any changes, as continued use of the Application indicates your approval of
        the updates.
      </Text>

      <Text mt="md">This privacy policy is effective as of 2025-01-26.</Text>

      <Divider my="lg" />

      <Title order={3} mt="lg">
        Your Consent
      </Title>
      <Text>
        By using the Application, you consent to the processing of your information as described in
        this Privacy Policy.
      </Text>

      <Divider my="lg" />

      <Title order={3} mt="lg">
        Contact Us
      </Title>
      <Text>
        If you have any questions regarding privacy while using the Application, contact the Service
        Provider via email at <Anchor href={`mailto:${email}`}>{formattedEmail}</Anchor>.
      </Text>
    </Container>
  );
};

export const PrivacyPolicyPage = makePage(PrivacyPolicyPageImpl, TAB_NAME_BASE + "Privacy Policy", {
  hideLocationUI: true,
});
