import { ActionIcon, Affix } from "@mantine/core";
import type { ReactNode } from "react";

interface FloatingActionButtonProps {
  icon: ReactNode;
  onClick: () => void;
}

export const FloatingActionButton = (props: FloatingActionButtonProps) => {
  return (
    <Affix position={{ bottom: 40, right: 40 }}>
      <ActionIcon
        variant="filled"
        bg="grape"
        c="white"
        radius="xl"
        size={60}
        onClick={props.onClick}
      >
        {props.icon}
      </ActionIcon>
    </Affix>
  );
};
