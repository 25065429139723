import { Box, Text } from "@mantine/core";
import { memo, type ReactNode } from "react";

interface StandardEmptyStateProps {
  icon: ReactNode;
  message: string;
}

export const StandardEmptyState = memo((props: StandardEmptyStateProps) => {
  return (
    <Box c="dimmed" className="col justify-center items-center w100 h100">
      <div>{props.icon}</div>
      <div>
        <Text align="center" className="pretty-wrap">
          {props.message}
        </Text>
      </div>
    </Box>
  );
});
