import { FaBiking, FaCarSide, FaQuestion, FaTrain, FaWalking } from "react-icons/fa";
import { TransportationMode } from "types/geo";

interface TransportationModeIconProps {
  mode?: TransportationMode;
}

export const TransportationModeIcon = (props: TransportationModeIconProps) => {
  if (props.mode === TransportationMode.BIKING) return <FaBiking />;
  else if (props.mode === TransportationMode.DRIVING) return <FaCarSide />;
  else if (props.mode === TransportationMode.WALKING) return <FaWalking />;
  else if (props.mode === TransportationMode.PUBLIC_TRANSPORTATION) return <FaTrain />;
  return <FaQuestion />;
};
