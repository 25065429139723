// https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url

import type { Undefined } from "types/utils";

// Note that this has some caveats, but it's good enough for now
export const extractURLFromString = (string: string): Undefined<URL> => {
  try {
    return new URL(string);
  } catch {
    return undefined;
  }
};
