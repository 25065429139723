import { useCallback, useEffect, useMemo, useState } from "react";
import type { PartialRecord } from "types/utils";
import { arrayRemoveFirstStrict } from "utils/arrays";
import { assertDefined } from "utils/misc";
import { getPreferredModeOfTransport, setPreferredModeOfTransport } from "./localStorageAccessors";
import { PREFERRED_MODE_OF_TRANSPORT_KEY } from "./localStorageKeys";

const subscribers: PartialRecord<string, Array<(val: any) => void>> = {};

// Using this as the basis of our local storage hook is nice because we
// have one source of truth for defaults, getter logic and setter logic
// This subscribes to changes anywhere in the application
const useAccessorBasedLocalStorageHook = <R, W>(
  key: string,
  localStorageGetter: () => R,
  localStorageSetter: (val: W) => void
): [R, (val: W) => void] => {
  const [hookValue, hookSetter] = useState(localStorageGetter());

  useEffect(() => {
    if (!subscribers[key]) subscribers[key] = [];
    subscribers[key].push(hookSetter);
    return () => {
      subscribers[key] = arrayRemoveFirstStrict(
        assertDefined(subscribers[key]),
        (x) => x === hookSetter
      );
    };
  }, [key]);

  const exportedSetter = useCallback(
    (val: W) => {
      localStorageSetter(val);
      const newValue = localStorageGetter();
      subscribers[key]?.forEach((subscriber) => subscriber(newValue));
    },
    [localStorageGetter, key, localStorageSetter]
  );

  const exportedValue = useMemo(() => {
    return localStorageGetter();
    //Intentionally adding extra dependency here so that a change in the value reruns the getter
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorageGetter, hookValue]);

  return [exportedValue, exportedSetter];
};

///////////////////////////////////
// PREFERRED MODES OF TRANSPORT
///////////////////////////////////

export const usePreferredModeOfTransport = () => {
  return useAccessorBasedLocalStorageHook(
    PREFERRED_MODE_OF_TRANSPORT_KEY,
    getPreferredModeOfTransport,
    setPreferredModeOfTransport
  );
};
