import { usePostHog } from "posthog-js/react";
import { useCallback } from "react";

const useAnalytics = () => {
  const posthog = usePostHog();

  const logEvent = useCallback(
    (eventName: string, eventProperties?: Record<string, any>) => {
      // https://posthog.com/docs/product-analytics/capture-events
      posthog?.capture(eventName, eventProperties);
    },
    [posthog]
  );

  return { logEvent };
};

export const Analytics = { useAnalytics };
