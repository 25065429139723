import type { FeatureLike } from "ol/Feature";
import Feature from "ol/Feature";
import Map from "ol/Map";
import View from "ol/View";
import { pointerMove } from "ol/events/condition";
import { type Geometry, Point, Polygon } from "ol/geom";
import { Select } from "ol/interaction";
import VectorLayer from "ol/layer/Vector";
import { fromLonLat } from "ol/proj";
import VectorSource from "ol/source/Vector";
import type Style from "ol/style/Style";
import { useCallback, useEffect, useRef } from "react";
import type { GroupID, Heatmap, HeatmapPlace, PlaceID } from "types/internal_apis/get_heatmap";
import {
  getCommonControls,
  getCommonTileLayer,
  getCommonViewArgs,
  MAP_TARGET_ELEMENT_ID,
} from "utils/openLayers/commonMapParts";
import {
  addPolygonsToSource,
  centerMapAroundVectorSource,
} from "utils/openLayers/commonOperations";

export interface HeatmapConfigurationPointProperties {
  geometry: Point;
  groupId: GroupID;
  placeId: PlaceID;
}

export const useHeatmapMap = (
  polygonStyleFunction: (feature: FeatureLike) => Style,
  pointStyleFunction: (feature: FeatureLike) => Style,
  heatmap: Heatmap,
  onFeatureSelected?: (features: Feature<Polygon>[]) => void,
  onPointSelected?: (features: Feature<Point>[]) => void
) => {
  const mapRef = useRef<Map>();
  const polygonSourceRef = useRef<VectorSource>();
  const pointSourceRef = useRef<VectorSource>();
  const polygonLayerRef = useRef<VectorLayer<VectorSource<Feature<Geometry>>, Feature<Geometry>>>();
  const pointLayerRef = useRef<VectorLayer<VectorSource<Feature<Geometry>>, Feature<Geometry>>>();

  useEffect(() => {
    //https://stackoverflow.com/questions/59819792/change-openlayers-map-color-dark-and-light-style
    const tileLayer = getCommonTileLayer({ desaturate: true });

    const polygonSource = new VectorSource({});
    const pointSource = new VectorSource({});
    polygonSourceRef.current = polygonSource;
    const polygonLayer = new VectorLayer({
      //Polygons
      source: polygonSource,
      style: polygonStyleFunction,
    });
    polygonLayerRef.current = polygonLayer;

    pointSourceRef.current = pointSource;
    const pointLayer = new VectorLayer({
      source: pointSource,
      style: pointStyleFunction,
    });
    pointLayerRef.current = pointLayer;

    const map = new Map({
      layers: [tileLayer, polygonLayer, pointLayer],
      controls: getCommonControls(),
      view: new View(getCommonViewArgs()),
      target: MAP_TARGET_ELEMENT_ID,
    });
    mapRef.current = map;

    const polygonSelectOnHoverInteraction = new Select({
      layers: [polygonLayer],
      condition: pointerMove,
    });

    const onPolygonSelectChanged = () => {
      onFeatureSelected?.(
        polygonSelectOnHoverInteraction
          .getFeatures()
          .getArray()
          .filter((x): x is Feature<Polygon> => x.getGeometry() instanceof Polygon)
      );
    };

    const pointSelectOnHoverInteraction = new Select({
      layers: [pointLayer],
      condition: pointerMove,
      hitTolerance: 5,
    });

    const onPointSelectChanged = () => {
      onPointSelected?.(
        pointSelectOnHoverInteraction
          .getFeatures()
          .getArray()
          .filter((x): x is Feature<Point> => x.getGeometry() instanceof Point)
      );
    };

    polygonSelectOnHoverInteraction.on("select", onPolygonSelectChanged);
    map.addInteraction(polygonSelectOnHoverInteraction);
    pointSelectOnHoverInteraction.on("select", onPointSelectChanged);
    map.addInteraction(pointSelectOnHoverInteraction);

    return () => {
      polygonSelectOnHoverInteraction.un("select", onPolygonSelectChanged);
      map.removeInteraction(polygonSelectOnHoverInteraction);
      polygonSelectOnHoverInteraction.un("select", onPointSelectChanged);
      map.removeInteraction(pointSelectOnHoverInteraction);
    };
  }, [onFeatureSelected, onPointSelected, pointStyleFunction, polygonStyleFunction]);

  const addPoints = useCallback(
    (places: HeatmapPlace[]) => {
      for (const place of places) {
        for (const point of place.points) {
          const properties: HeatmapConfigurationPointProperties = {
            geometry: new Point(fromLonLat(point)),
            groupId: place.group_id,
            placeId: place.id,
          };
          pointSourceRef.current?.addFeature(new Feature(properties));
        }
      }
    },
    [pointSourceRef]
  );

  const clearMap = useCallback(() => {
    polygonSourceRef.current?.clear();
  }, [polygonSourceRef]);

  // This is for loading in the heatmap
  useEffect(() => {
    clearMap();
    addPolygonsToSource(polygonSourceRef.current, heatmap.feature_collection);
    addPoints(Object.values(heatmap.places));
    centerMapAroundVectorSource(polygonSourceRef.current, mapRef.current);
  }, [addPoints, clearMap, heatmap]);

  return {
    mapRef,
    polygonLayer: polygonLayerRef.current,
    pointLayer: pointLayerRef.current,
  };
};
