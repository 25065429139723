import { useEffect, useRef, useState, type ReactNode } from "react";
import type { Undefined } from "types/utils";
import { createContext, useContextSelector } from "use-context-selector";

const ScreenSizeContext = createContext<boolean>(false);

const MOBILE_THRESHOLD = 650;

function ScreenSizeContextWrapper(props: { children: ReactNode }) {
  const elementRef = useRef<HTMLDivElement>(null);
  const [isSmallScreen, setIsSmallScreen] = useState<Undefined<boolean>>(undefined);

  useEffect(() => {
    if (elementRef.current) {
      setIsSmallScreen(elementRef.current.offsetWidth <= MOBILE_THRESHOLD);

      const observer = new ResizeObserver((entries) => {
        if (!entries.length) {
          return;
        }
        const { width } = entries[0].contentRect;
        setIsSmallScreen(width <= MOBILE_THRESHOLD);
      });

      observer.observe(elementRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return (
    <div
      ref={elementRef}
      style={{
        width: "100vw",
        height: "100vh",
      }}
    >
      {isSmallScreen !== undefined && (
        <ScreenSizeContext.Provider value={isSmallScreen}>
          {props.children}
        </ScreenSizeContext.Provider>
      )}
    </div>
  );
}

export default ScreenSizeContextWrapper;

export const useSmallScreen = () => {
  const val = useContextSelector(ScreenSizeContext, (c) => c);
  return val;
};
