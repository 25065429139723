import { HeatmapTransportationMode } from "types/internal_apis/get_heatmap";
import { toTitleCase } from "utils/strings";

export function transportationModeToString(mode: HeatmapTransportationMode) {
  switch (mode) {
    case HeatmapTransportationMode.BIKING:
      return "biking";
    case HeatmapTransportationMode.PUBLIC_TRANSPORTATION:
      return "public transportation";
    case HeatmapTransportationMode.DRIVING:
      return "car";
    case HeatmapTransportationMode.WALKING:
      return "walking";
  }
}

export const transportationModeDropdownOptions = [
  {
    value: HeatmapTransportationMode.BIKING,
    label: toTitleCase(transportationModeToString(HeatmapTransportationMode.BIKING)),
  },
  {
    value: HeatmapTransportationMode.PUBLIC_TRANSPORTATION,
    label: toTitleCase(transportationModeToString(HeatmapTransportationMode.PUBLIC_TRANSPORTATION)),
  },
  {
    value: HeatmapTransportationMode.DRIVING,
    label: toTitleCase(transportationModeToString(HeatmapTransportationMode.DRIVING)),
  },
  {
    value: HeatmapTransportationMode.WALKING,
    label: toTitleCase(transportationModeToString(HeatmapTransportationMode.WALKING)),
  },
];
