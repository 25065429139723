import type Feature from "ol/Feature";
import type { FeatureLike } from "ol/Feature";
import Map from "ol/Map";
import View from "ol/View";
import type { Geometry } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import type Style from "ol/style/Style";
import { useEffect, useRef } from "react";
import type { Heatmap } from "types/internal_apis/get_heatmap";
import { getCommonViewArgs, MAP_TARGET_ELEMENT_ID } from "utils/openLayers/commonMapParts";
import { addPolygonsToSource } from "utils/openLayers/commonOperations";

export const useEmbeddedHeatmapMap = (
  polygonStyleFunction: (feature: FeatureLike) => Style,
  heatmap: Heatmap
) => {
  const mapRef = useRef<Map>();
  const polygonSourceRef = useRef<VectorSource>();
  const polygonLayerRef = useRef<VectorLayer<VectorSource<Feature<Geometry>>, Feature<Geometry>>>();

  useEffect(() => {
    const polygonSource = new VectorSource({});
    polygonSourceRef.current = polygonSource;
    const polygonLayer = new VectorLayer({
      source: polygonSource,
      style: polygonStyleFunction,
    });
    polygonLayerRef.current = polygonLayer;

    const map = new Map({
      layers: [polygonLayer],
      controls: [],
      view: new View(getCommonViewArgs()),
      target: MAP_TARGET_ELEMENT_ID,
    });
    mapRef.current = map;
  }, [polygonStyleFunction]);

  useEffect(() => {
    addPolygonsToSource(polygonSourceRef.current, heatmap.feature_collection);
  }, [heatmap]);

  return {
    mapRef,
    polygonLayer: polygonLayerRef.current,
  };
};
