import { Button, Space, Text, Textarea, Title } from "@mantine/core";
import axios from "axios";
import Color from "color";
import { type ChangeEvent, useCallback, useEffect, useState } from "react";
import { Panel, PanelGroup } from "react-resizable-panels";

import { StandardPanelResizeHandle } from "common_ui/StandardPanelResizeHandle";
import type { FeatureCollection, Feature as FeatureType, MultiPolygon, Polygon } from "geojson";
import Feature, { type FeatureLike } from "ol/Feature";
import { Point } from "ol/geom";
import { fromLonLat } from "ol/proj";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import { makePage } from "page_setup/makePage";
import type { Undefined } from "types/utils";
import { range } from "utils/arrays";
import { getEnvVariable } from "utils/env";
import { clamp } from "utils/numbers";
import {
  COMMON_LAT_LANG_COORDINATE_PROJECTION,
  OPEN_LAYERS_DEFAULT_MAP_PROJECTION,
} from "utils/openLayers/OpenLayersConstants";
import { defaultGeojsonObject } from "./data/defaultPolygons";
import { useDevMap } from "./DevMap";
import { PersistentMapAnchor } from "common_ui/openlayers_specific/PersistentMapAnchor";

const ISOCHRONE_BUCKETS = 3;
const ISOCHRONE_TIME_MS = 20 * 60 * 1000;

const styleFunction = (feature: FeatureLike) => {
  const min = Color("green");
  const max = Color("red");
  let bucketNumber = 0;

  //For Graphhopper
  const explicitBucketNumber = parseInt(feature.getProperties()["bucket"]);
  if (!isNaN(explicitBucketNumber)) bucketNumber = explicitBucketNumber;

  //For Targomo
  const explicitTime = parseInt(feature.getProperties()["time"]);
  if (!isNaN(explicitTime)) {
    const incrementInSeconds = ISOCHRONE_TIME_MS / ISOCHRONE_BUCKETS / 1000;
    bucketNumber = Math.floor(explicitTime / incrementInSeconds);
  }

  const color = min.mix(max, clamp(bucketNumber / ISOCHRONE_BUCKETS, 0, 1));

  return [
    new Style({
      stroke: new Stroke({
        color: color.string(),
        width: 3,
      }),
      fill: new Fill({
        color: color.alpha(0.9).string(),
      }),
      zIndex: ISOCHRONE_BUCKETS - bucketNumber,
    }),
  ];
};

const IsochroneTesterImpl = () => {
  const [textAreaInput, setTextAreaInput] = useState(defaultGeojsonObject);

  const { addPolygons, clearPolygons, getDevMapPoints, pointSourceRef, mapRef } = useDevMap({
    styleFunction,
  });

  // Add some default point
  useEffect(() => {
    if (!pointSourceRef.current) return;
    pointSourceRef.current.addFeature(
      new Feature({
        geometry: new Point(fromLonLat([-73.9964, 40.7057])),
      })
    );
  }, [pointSourceRef]);

  const onTextInputChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setTextAreaInput(event.target.value);
    },
    [setTextAreaInput]
  );

  const onChangePolygonManually = useCallback(() => {
    addPolygons(JSON.parse(textAreaInput));
  }, [textAreaInput, addPolygons]);

  const tryGraphhopperAPI = useCallback(
    async (url: string, profile: string) => {
      // Remote: https://docs.graphhopper.com/#operation/getIsochrone
      // Local: https://github.com/graphhopper/graphhopper/blob/master/docs/web/api-doc.md#isochrone
      // Known issues: https://discuss.graphhopper.com/t/struggling-with-public-transport-isochrones-on-local-install/4554
      const ghInstance = axios.create({
        baseURL: url,
        timeout: 25000,
      });

      const promises = getDevMapPoints().map(async (point) => {
        return await ghInstance.get("/isochrone", {
          params: {
            point: `${point[1]},${point[0]}`,
            time_limit: ISOCHRONE_TIME_MS / 1000,
            profile,
            buckets: ISOCHRONE_BUCKETS,
            key: getEnvVariable("REACT_APP_GRAPHHOPPER_TESTING_API_KEY"),
            "pt.earliest_departure_time": new Date().toISOString(),
          },
        });
      });

      const responses = await Promise.all(promises || []);
      responses.forEach((r) => {
        const polygons = r.data.polygons as Undefined<FeatureType<Polygon>[]>;
        if (polygons) addPolygons(polygons, COMMON_LAT_LANG_COORDINATE_PROJECTION);
      });
    },
    [addPolygons, getDevMapPoints]
  );

  const tryValhallaApi = useCallback(
    async (url: string, travelMode: string) => {
      // https://valhalla.github.io/valhalla/api/isochrone/api-reference/
      const vInstance = axios.create({
        baseURL: url,
        timeout: 25000,
      });

      const promises = getDevMapPoints().map(async (point) => {
        return await vInstance.get("/isochrone", {
          params: {
            json: JSON.stringify({
              costing: travelMode,
              polygons: true,
              locations: [{ lat: point[1], lon: point[0] }],
              denoise: 0.3,
              contours: [
                {
                  time: ISOCHRONE_TIME_MS / 60000,
                },
              ],
            }),
          },
        });
      });

      const responses = await Promise.all(promises || []);
      responses.forEach((r) => {
        const polygons = r.data as Undefined<FeatureCollection<Polygon>>;
        if (polygons) addPolygons(polygons, COMMON_LAT_LANG_COORDINATE_PROJECTION);
      });
    },
    [addPolygons, getDevMapPoints]
  );

  const tryTargomoApi = useCallback(
    async (url: string, transitOptions: unknown) => {
      const buckets: number[] = [];
      for (const bucket of range(ISOCHRONE_BUCKETS)) {
        const multiple = bucket + 1;
        buckets.push((ISOCHRONE_TIME_MS / 1000 / ISOCHRONE_BUCKETS) * multiple);
      }

      // https://docs.targomo.com/#tag/Isochrone-API
      const vInstance = axios.create({
        baseURL: url,
        timeout: 25000,
      });

      const r = await vInstance.post(
        "/v1/polygon",
        {
          polygon: {
            values: buckets,
            intersectionMode: "union",
            serializer: "geojson",
          },
          edgeWeight: "time",
          sources: getDevMapPoints().map((point, indx) => ({
            lat: point[1],
            lng: point[0],
            id: indx,
            tm: transitOptions,
          })),
        },
        {
          params: {
            key: getEnvVariable("REACT_APP_TARGOMO_TESTING_API_KEY"),
          },
        }
      );

      const polygons = r.data.data as Undefined<FeatureCollection<MultiPolygon>>;
      if (polygons) addPolygons(polygons, OPEN_LAYERS_DEFAULT_MAP_PROJECTION);
    },
    [addPolygons, getDevMapPoints]
  );

  return (
    <PanelGroup autoSaveId="devIsochroneTesterPanelGroup" direction="horizontal">
      <Panel style={{ padding: 8 }}>
        <p>Manual GeoJson:</p>
        <Textarea
          styles={{ input: { resize: "vertical" } }}
          value={textAreaInput}
          onChange={onTextInputChange}
        />
        <Space h="sm" />
        <Button onClick={onChangePolygonManually}>Add Polygon Manually</Button>
        <Space h="sm" />
        <Button onClick={clearPolygons}>Clear Polygons</Button>
        <Space h="sm" />

        <Title>GraphHopper</Title>
        <Button onClick={() => tryGraphhopperAPI("https://graphhopper.com/api/1/", "car")}>
          remote, car
        </Button>
        <Space h="sm" />
        <Button onClick={() => tryGraphhopperAPI("http://localhost:8989/", "pt")}>
          local, transit
        </Button>
        <Space h="sm" />

        <Title>Valhalla</Title>
        <Text>Valhalla APIs will only use the max bucket size</Text>
        <Button onClick={() => tryValhallaApi("https://valhalla1.openstreetmap.de/", "auto")}>
          remote, car
        </Button>
        <Space h="sm" />
        <Button onClick={() => tryValhallaApi("https://valhalla1.openstreetmap.de/", "multimodal")}>
          remote, transit
        </Button>
        <Space h="sm" />

        <Title>Targomo</Title>
        <Button onClick={() => tryTargomoApi("https://api.targomo.com/northamerica/", { car: {} })}>
          remote, car
        </Button>
        <Space h="sm" />
        <Button
          onClick={() => tryTargomoApi("https://api.targomo.com/northamerica/", { transit: {} })}
        >
          remote, transit
        </Button>
      </Panel>

      <StandardPanelResizeHandle />

      <Panel maxSize={80} minSize={20}>
        <PersistentMapAnchor className="w-100 h-full" map={mapRef.current} />
      </Panel>
    </PanelGroup>
  );
};

export const DevIsochroneTester = makePage(IsochroneTesterImpl, "<Dev Page>");
