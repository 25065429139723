import { useEffect, useRef } from "react";
import type Map from "ol/Map";
import type { Undefined } from "types/utils";
import { MAP_TARGET_ELEMENT_ID } from "utils/openLayers/commonMapParts";

// Why do something like this instead of just having maps be their own component that contain both the map
// object and its anchor?
// Because when the user goes from normal mode to small screen mode (like maybe their phone
// orientation flipped), that would cause the DOM to change, and then the component will
// be re-initiated. That would cause the map (with its zoom, bounds, etc) to reset, which would be
// jarring
export const PersistentMapAnchor = (props: { map: Undefined<Map>; className?: string }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && props.map) {
      if (props.map.getTargetElement() !== ref.current) {
        props.map.setTarget(ref.current);
      }
    }
  }, [props.map]);

  return <div className={props.className} ref={ref} id={MAP_TARGET_ELEMENT_ID} />;
};
