import type { PartialGroup, PartialPlace } from "./types";

export const validateGroup = (group: PartialGroup): string | null => {
  if (!group.max_bucket_in_s) return "Condition has no time entry";
  if (group.places.length === 0) return "Condition has no places";
  for (const place of group.places) {
    const validationError = validatePlace(place);
    if (validationError) return validationError;
  }
  return null;
};

const validatePlace = (place: PartialPlace): string | null => {
  if (!place.points || place.points?.length <= 0) return "Place is invalid";
  if (!place.name) return "Place has no name";
  return null;
};
