import { Button, List, Modal, Space, Stack, Text, TextInput, Title } from "@mantine/core";
import { CloudFunctions } from "apis/internal/cloudFunctions";
import { LoadingIndicator } from "common_ui/LoadingIndicator";
import { UserCoordinatesDisplayer } from "common_ui/UserCoordinatesDisplayer";
import { useCallback, useState } from "react";
import type {
  AppleMapsPlace,
  AppleMapsPlaceRequest,
  AppleMapsPlaceRequestResult,
} from "types/internal_apis/get_heatmap_places_open_search";
import type { Optional } from "types/utils";
import { coalesceEmptyArray } from "utils/arrays";
import { useUserCoordinates } from "utils/geo";
import { v4 as uuid } from "uuid";
import type { PartialPlace } from "./types";

interface HeatmapPointChooserOpenSearchModalProps {
  open: boolean;
  onPlacesChosen: (places: PartialPlace[]) => void;
  onModalClose: () => void;
}

export const HeatmapPointChooserOpenSearchModal = (
  props: HeatmapPointChooserOpenSearchModalProps
) => {
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [retrievedPlaces, setRetrievedPlaces] = useState<Optional<AppleMapsPlaceRequestResult>>();
  const userLocation = useUserCoordinates();

  const isValidSearch = query.trim().length > 2;

  const onQueryChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.currentTarget.value);
  }, []);

  const findPlaces = useCallback(async () => {
    if (!isValidSearch) return;
    setIsLoading(true);
    setRetrievedPlaces(null);
    const args: AppleMapsPlaceRequest = {
      query,
      bias: userLocation,
    };
    const results = await CloudFunctions.ApiGetHeatmapPlacesOpenSearch(args);
    setRetrievedPlaces(results);
    setIsLoading(false);
  }, [isValidSearch, query, userLocation]);

  const onClose = useCallback(() => {
    if (isLoading) return;
    setQuery("");
    setRetrievedPlaces(null);
    props.onModalClose();
  }, [isLoading, props]);

  const onAddFoundPlaces = useCallback(() => {
    const mappedPlaces: PartialPlace[] = coalesceEmptyArray(
      retrievedPlaces?.places.map((place) => ({
        id: uuid(),
        name: `${place.name} (${place.formatted_address.join(", ")})`,
        points: [place.coordinates],
      }))
    );
    props.onPlacesChosen(mappedPlaces);
    onClose();
  }, [onClose, props, retrievedPlaces?.places]);

  const onInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        findPlaces();
      }
    },
    [findPlaces]
  );

  return (
    <Modal.Root centered opened={props.open} onClose={onClose} size="xl">
      <Modal.Overlay blur={3} />
      <Modal.Content>
        <Modal.Header>
          <div>
            <Title order={3}>What places are you looking for?</Title>
            <UserCoordinatesDisplayer />
          </div>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Stack>
            <TextInput
              placeholder="Enter an open-ended search, like 'chess club' or 'Macy's'"
              value={query}
              onChange={onQueryChange}
              disabled={isLoading}
              onKeyDown={onInputKeyDown}
              data-autofocus // Using this instead of autofocus because of the focus trap the modal uses
            />
            <Space h="sm" />
            <LoadingIndicator shouldShow={isLoading} />
            <div className="button-panel">
              <Button onClick={findPlaces} disabled={!isValidSearch}>
                Search!
              </Button>
            </div>

            {retrievedPlaces && (
              <>
                <Title order={5}>Places</Title>
                <Text>We found {retrievedPlaces.places.length} places.</Text>
                <Button onClick={onAddFoundPlaces}>Add all places</Button>
                <List spacing={8}>
                  {retrievedPlaces.places.map((place, index) => (
                    <List.Item key={index}>
                      <PlaceComponent place={place} />
                    </List.Item>
                  ))}
                </List>
              </>
            )}
          </Stack>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

interface PlaceComponentProps {
  place: AppleMapsPlace;
}

const PlaceComponent = (props: PlaceComponentProps) => {
  const { place } = props;
  return (
    <div>
      <Text>{place.name}</Text>
      <Text size="sm" c="dimmed">
        {place.formatted_address.join(", ")}
      </Text>
    </div>
  );
};
