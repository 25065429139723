import { makePage } from "page_setup/makePage";
import type { Heatmap } from "types/internal_apis/get_heatmap";

import { HeatmapConfigurationInner } from "pages/heatmap_configuration/HeatmapConfigurationInner";
import type { StoredHeatmap } from "types/heatmap_storage/storedHeatmap";
const sampleHeatmap = require("./data/exampleHeatmap.json");

const devStoredHeatmap: StoredHeatmap = {
  heatmap: sampleHeatmap as unknown as Heatmap,
  groupMetadata: {},
  id: "DEV_HEATMAP",
  creationTimestamp: Date.now(),
};

const DevHeatmapConfigurationImpl = () => {
  return <HeatmapConfigurationInner heatmap={devStoredHeatmap} />;
};

export const DevHeatmapConfiguration = makePage(DevHeatmapConfigurationImpl, "<Dev Page>");
