import axios from "axios";
import type { Coordinates } from "types/geo";
import type { OSMResult } from "./types";

//https://photon.komoot.io/
interface PhotonReverseGeolocationResponse {
  message?: string;
  features?: Array<{
    geometry: PhotonReverseGeolocationGeometry;
    type: string;
    properties: PhotonReverseGeoPlaceProperties;
  }>;
  type?: string;
}

interface PhotonReverseGeolocationGeometry {
  coordinates?: [number, number];
  type: string;
}

interface PhotonReverseGeoPlaceProperties {
  osm_id?: number;
  country?: string;
  city?: string;
  countrycode?: string;
  postcode?: string;
  county?: string;
  type?: string;
  osm_type?: string;
  osm_key?: string;
  street?: string;
  district?: string;
  osm_value?: string;
  name?: string;
  state?: string;
  housenumber?: string;
}

export const reverseGeocodeFromPhoton = async (coords: Coordinates): Promise<OSMResult> => {
  let queryUrl = "https://photon.komoot.io/reverse?";
  queryUrl += `lat=${coords.latitude}&lon=${coords.longitude}`;

  const response = (await axios.get(queryUrl)).data as PhotonReverseGeolocationResponse;
  if (
    !response.message &&
    response.features &&
    response.features[0] &&
    response.features[0].geometry.coordinates &&
    response.features[0].properties.name
  ) {
    const properties = response.features[0].properties;
    const osmResult: OSMResult = {
      name: response.features[0].properties.name,
      coords: {
        latitude: response.features[0].geometry.coordinates[1],
        longitude: response.features[0].geometry.coordinates[0],
      },
      address: {
        country: properties.country,
        region: properties.state,
        city: properties.city,
        street: properties.street,
        number: properties.housenumber,
        postcode: properties.postcode,
      },
      source: "photon",
    };
    return osmResult;
  } else {
    throw new Error("Error Processing final place information! " + response.message);
  }
};
