import { Container, Stack, Text, Title } from "@mantine/core";
import { LoadingIndicator } from "common_ui/LoadingIndicator";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { makePage, TAB_NAME_BASE } from "page_setup/makePage";

const REDIRECTION_TIMER_IN_S = 1.5;

const NoPageFoundPageImpl = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => navigate("/"), REDIRECTION_TIMER_IN_S * 1000);
  });

  return (
    <Container>
      <Stack align="center" justify="center">
        <Title>You look a little lost...</Title>
        <LoadingIndicator shouldShow size={40} />
        <Text>
          We didn't find what you were looking for, so we're redirecting you to the home page!
        </Text>
      </Stack>
    </Container>
  );
};

export const NoPageFoundPage = makePage(NoPageFoundPageImpl, TAB_NAME_BASE + "404");
