import type { FeatureCollection, Feature as FeatureType } from "geojson";
import type { FeatureLike } from "ol/Feature";
import Feature from "ol/Feature";
import Map from "ol/Map";
import View from "ol/View";
import type { Geometry } from "ol/geom";
import { default as GeomPoint } from "ol/geom/Point";
import { Draw, Modify, Snap } from "ol/interaction.js";
import VectorLayer from "ol/layer/Vector";
import { fromLonLat, transform } from "ol/proj";
import VectorSource from "ol/source/Vector";
import type Style from "ol/style/Style";
import { useCallback, useEffect, useRef } from "react";
import type { Point } from "types/internal_apis/get_heatmap";
import type { Undefined } from "types/utils";
import {
  COMMON_LAT_LANG_COORDINATE_PROJECTION,
  OPEN_LAYERS_DEFAULT_MAP_PROJECTION,
} from "utils/openLayers/OpenLayersConstants";
import {
  getCommonControls,
  getCommonPointStyle,
  getCommonTileLayer,
  getCommonViewArgs,
  MAP_TARGET_ELEMENT_ID,
} from "utils/openLayers/commonMapParts";
import { addPolygonsToSource } from "utils/openLayers/commonOperations";

interface DevMapConfig {
  styleFunction?: (feature: FeatureLike) => Style[];
  onPointsChange?: () => void;
}

export const useDevMap = (config: DevMapConfig) => {
  const { styleFunction, onPointsChange } = config;
  const mapRef = useRef<Map>();
  const polygonSourceRef = useRef<VectorSource>();
  const pointSourceRef = useRef<VectorSource>();
  const pointLayerRef = useRef<VectorLayer<VectorSource<Feature<Geometry>>, Feature<Geometry>>>();

  useEffect(() => {
    const polygonSource = new VectorSource({});
    const pointSource = new VectorSource({});
    polygonSourceRef.current = polygonSource;
    pointSourceRef.current = pointSource;

    const pointChangeListener = () => onPointsChange?.();
    pointSource.on("change", pointChangeListener);

    const pointLayer = new VectorLayer({
      //Points
      source: pointSource,
      style: getCommonPointStyle(),
    });

    pointLayerRef.current = pointLayer;

    mapRef.current = new Map({
      target: MAP_TARGET_ELEMENT_ID,
      layers: [
        getCommonTileLayer(),
        new VectorLayer({
          //Isochrone polygons
          source: polygonSource,
          style: styleFunction,
        }),
        pointLayer,
      ],
      controls: getCommonControls(),
      view: new View(getCommonViewArgs()),
    });

    //Allow us to modify points
    const modify = new Modify({ source: pointSourceRef.current });

    //Allow us to add new points
    const draw = new Draw({
      source: pointSourceRef.current,
      type: "Point",
    });

    //Allow us to snap to points when editing
    const snap = new Snap({ source: pointSourceRef.current });
    mapRef.current.addInteraction(modify);
    mapRef.current.addInteraction(draw);
    mapRef.current.addInteraction(snap);

    const map = mapRef.current;
    return () => {
      map.removeInteraction(modify);
      map.removeInteraction(draw);
      map.removeInteraction(snap);
      pointSource.un("change", pointChangeListener);
    };
  }, [onPointsChange, styleFunction]);

  const addPolygons = useCallback(
    (features: FeatureType[] | FeatureCollection, projection?: Undefined<string>) => {
      addPolygonsToSource(polygonSourceRef.current, features, projection);
    },
    [polygonSourceRef]
  );

  const addPoints = useCallback(
    (points: Point[]) => {
      pointSourceRef.current?.addFeatures(
        points.map(
          (point) =>
            new Feature({
              geometry: new GeomPoint(fromLonLat(point)),
            })
        )
      );
    },
    [pointSourceRef]
  );

  const clearPolygons = useCallback(() => {
    polygonSourceRef.current?.clear();
  }, [polygonSourceRef]);

  const clearPoints = useCallback(() => {
    pointSourceRef.current?.clear();
    pointLayerRef.current?.changed();
  }, [pointSourceRef]);

  const getDevMapPoints = useCallback(() => {
    let points = pointSourceRef.current
      ?.getFeatures()
      .map((x) => (x.getGeometry() as GeomPoint).getCoordinates());
    points = points?.map((point) =>
      transform(point, OPEN_LAYERS_DEFAULT_MAP_PROJECTION, COMMON_LAT_LANG_COORDINATE_PROJECTION)
    );
    return points ?? [];
  }, [pointSourceRef]);

  return {
    mapRef,
    polygonSourceRef,
    pointSourceRef,
    addPolygons,
    clearPolygons,
    getDevMapPoints,
    clearPoints,
    addPoints,
  };
};
