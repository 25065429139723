import { Box, Button, Checkbox, Space, Title } from "@mantine/core";
import { FloatingActionButton } from "common_ui/FloatingActionButton";
import { PersistentMapAnchor } from "common_ui/openlayers_specific/PersistentMapAnchor";
import { StandardPanelResizeHandle } from "common_ui/StandardPanelResizeHandle";
import type Feature from "ol/Feature";
import type { FeatureLike } from "ol/Feature";
import type { Point, Polygon } from "ol/geom";
import Style from "ol/style/Style";
import { useSmallScreen } from "page_setup/ScreenSizeContext";
import type {
  HeatmapPointChooserPageNavigationArgs,
  PartialGroup,
} from "pages/heatmap_point_chooser/types";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { FiList, FiMap } from "react-icons/fi";
import { Panel, PanelGroup } from "react-resizable-panels";
import { useNavigate } from "react-router-dom";
import { saveHeatmapLocally } from "storage/localHeatmapStorage";
import type { StoredHeatmap } from "types/heatmap_storage/storedHeatmap";
import type {
  GroupID,
  Heatmap,
  HeatmapPartProperties,
  HeatmapPlace,
} from "types/internal_apis/get_heatmap";
import { useHeatmapPolygonStyling } from "utils/heatmap/heatmapParts";
import { useRenderVersion, useToggleState } from "utils/hooks";
import { assertDefined } from "utils/misc";
import { getCommonPointStyle } from "utils/openLayers/commonMapParts";
import { type HeatmapConfigurationPointProperties, useHeatmapMap } from "./HeatmapConfigurationMap";
import { HeatmapPartOverlay } from "./HeatmapPartOverlay";
import { GroupListElement } from "./HeatmapPlaceGroupListElement";
import { HeatmapPointOverlay } from "./HeatmapPointOverlay";
import "./HeatmapConfigurationInner.css";

interface HeatmapConfigurationProps {
  heatmap: StoredHeatmap;
}

const HeatmapConfigurationInnerImpl = (props: HeatmapConfigurationProps) => {
  const navigate = useNavigate();
  const smallScreen = useSmallScreen();
  const [showingConfigurationSection, toggleShowingConfigurationSection] = useToggleState(false);

  const heatmapRef = useRef<Heatmap>(props.heatmap.heatmap);
  const groupMetadataRef = useRef(props.heatmap.groupMetadata);

  const [selectedFeatures, setSelectedFeatures] = useState<Array<Feature<Polygon>>>([]);
  const [selectedPoints, setSelectedPoints] = useState<Array<Feature<Point>>>([]);

  const [, increaseRenderVersion] = useRenderVersion();

  const saveHeatmap = useCallback(() => {
    saveHeatmapLocally({ ...props.heatmap, groupMetadata: { ...groupMetadataRef.current } }).then(
      () => navigate("/heatmap/list")
    );
  }, [navigate, props.heatmap]);

  const {
    refreshHeatmapPartScores,
    getGroupMetadata,
    polygonStyleFunction,
    showPartsWithPartialMatchesRef,
    onlyShowBestPartsRef,
  } = useHeatmapPolygonStyling(heatmapRef, groupMetadataRef);

  // Called on mount
  useEffect(() => {
    refreshHeatmapPartScores();
  }, [refreshHeatmapPartScores]);

  const pointStyleFunction = useCallback(
    (feature: FeatureLike) => {
      const properties = feature.getProperties() as HeatmapConfigurationPointProperties;
      const associatedMetadata = getGroupMetadata(properties.groupId);
      if (associatedMetadata.hidePoints) return new Style();
      return getCommonPointStyle({ colorOverride: associatedMetadata.color });
    },
    [getGroupMetadata]
  );

  const onFeatureSelected = useCallback(
    (features: Feature<Polygon>[]) => {
      setSelectedFeatures([...features]);
    },
    [setSelectedFeatures]
  );

  const onPointSelected = useCallback(
    (features: Feature<Point>[]) => {
      setSelectedPoints([...features]);
    },
    [setSelectedPoints]
  );

  const { polygonLayer, pointLayer, mapRef } = useHeatmapMap(
    polygonStyleFunction,
    pointStyleFunction,
    heatmapRef.current,
    onFeatureSelected,
    onPointSelected
  );

  const onTogglePartsWithPartialMatches = useCallback(() => {
    showPartsWithPartialMatchesRef.current = !showPartsWithPartialMatchesRef.current;
    refreshHeatmapPartScores();
    increaseRenderVersion();
    polygonLayer?.changed();
  }, [
    increaseRenderVersion,
    polygonLayer,
    refreshHeatmapPartScores,
    showPartsWithPartialMatchesRef,
  ]);

  const onToggleOnlyShowBestParts = useCallback(() => {
    onlyShowBestPartsRef.current = !onlyShowBestPartsRef.current;
    increaseRenderVersion();
    polygonLayer?.changed();
  }, [increaseRenderVersion, onlyShowBestPartsRef, polygonLayer]);

  const updateImportance = useCallback(
    (groupId: GroupID, importance: number) => {
      const current = groupMetadataRef.current[groupId];
      groupMetadataRef.current[groupId] = { ...current, relativeImportance: importance };
      refreshHeatmapPartScores();
      increaseRenderVersion();
      polygonLayer?.changed();
    },
    [increaseRenderVersion, polygonLayer, refreshHeatmapPartScores]
  );

  const updateColor = useCallback(
    (groupId: GroupID, color: string) => {
      const current = groupMetadataRef.current[groupId];
      groupMetadataRef.current[groupId] = { ...current, color: color };
      increaseRenderVersion();
      pointLayer?.changed();
    },
    [increaseRenderVersion, pointLayer]
  );

  const toggleHidePoints = useCallback(
    (groupId: GroupID) => {
      const current = groupMetadataRef.current[groupId];
      groupMetadataRef.current[groupId] = { ...current, hidePoints: !current?.hidePoints };
      increaseRenderVersion();
      pointLayer?.changed();
    },
    [increaseRenderVersion, pointLayer]
  );

  const navigateBackToHeatmapCreation = useCallback(() => {
    const partialGroups: PartialGroup[] = [];
    const groupIDToPlaces: Record<string, HeatmapPlace[]> = {};

    for (const place of Object.values(heatmapRef.current.places)) {
      if (!groupIDToPlaces[place.group_id]) groupIDToPlaces[place.group_id] = [];
      groupIDToPlaces[place.group_id].push(place);
    }

    for (const group of Object.values(heatmapRef.current.groups)) {
      partialGroups.push({
        id: group.id,
        name: group.name,
        mode: group.transportation_mode,
        max_bucket_in_s: group.max_bucket_in_s,
        places: assertDefined(groupIDToPlaces[group.id]).map((point) => ({
          name: point.name,
          id: point.id,
          points: point.points.map((point) => ({ latitude: point[1], longitude: point[0] })),
        })),
        color: getGroupMetadata(group.id).color,
        hidePoints: getGroupMetadata(group.id).hidePoints,
      });
    }

    const locationState: HeatmapPointChooserPageNavigationArgs = {
      initialGroups: partialGroups,
    };

    navigate("/heatmap/create", { state: locationState });
  }, [getGroupMetadata, navigate]);

  const configurationSection = (
    <div className="heatmap-configuration-sidepanel-parent">
      <Title>Here's how your city fits you...</Title>

      <div className="button-panel">
        <Button onClick={navigateBackToHeatmapCreation}>Edit Heatmap</Button>
        <Button onClick={saveHeatmap}>Save Heatmap</Button>
      </div>
      <Space h="xs" />
      <Checkbox
        label="Include parts that don't satisfy all criteria"
        checked={showPartsWithPartialMatchesRef.current}
        onChange={onTogglePartsWithPartialMatches}
      />
      <Space h="xs" />
      <Checkbox
        label="Only show best matches"
        checked={onlyShowBestPartsRef.current}
        onChange={onToggleOnlyShowBestParts}
      />

      {Object.values(heatmapRef.current.groups).map((group, index) => (
        <GroupListElement
          key={index}
          heatmap={heatmapRef.current}
          groupId={group.id}
          groupMetadata={getGroupMetadata(group.id)}
          onImportanceChange={updateImportance}
          onToggleVisibility={toggleHidePoints}
          onChangeColor={updateColor}
        />
      ))}
    </div>
  );

  const mapSection = (
    <>
      <PersistentMapAnchor map={mapRef.current} className="w-100 h-full" />
      {mapRef.current &&
        selectedPoints.length === 0 &&
        selectedFeatures.map((feature, index) => (
          <HeatmapPartOverlay
            key={(feature.getProperties() as HeatmapPartProperties).id ?? index}
            feature={feature}
            map={assertDefined(mapRef.current)}
            heatmap={heatmapRef.current}
          />
        ))}

      {mapRef.current &&
        selectedPoints.map((feature, index) => (
          <HeatmapPointOverlay
            key={(feature.getProperties() as HeatmapConfigurationPointProperties).placeId ?? index}
            point={feature}
            map={assertDefined(mapRef.current)}
            heatmap={heatmapRef.current}
          />
        ))}
    </>
  );

  // We're using this strange pattern (using visibility: hidden instead of display: none) since the map
  // needs to have an actual size in the DOM for certain operations to work properly
  if (smallScreen) {
    return (
      <Box className="w100 h100">
        {showingConfigurationSection ? (
          <>
            <div key="config" style={{ display: "contents" }}>
              {configurationSection}
            </div>
            <div key="map" style={{ visibility: "hidden" }}>
              {mapSection}
            </div>
          </>
        ) : (
          <>
            <div key="config" style={{ display: "none" }}>
              {configurationSection}
            </div>
            <div key="map" style={{ display: "contents" }}>
              {mapSection}
            </div>
          </>
        )}
        <FloatingActionButton
          icon={showingConfigurationSection ? <FiMap /> : <FiList />}
          onClick={toggleShowingConfigurationSection}
        />
      </Box>
    );
  } else {
    return (
      <PanelGroup autoSaveId="devIsochroneTesterPanelGroup" direction="horizontal">
        <Panel>{configurationSection}</Panel>

        <StandardPanelResizeHandle />

        <Panel maxSize={80} minSize={20}>
          {mapSection}
        </Panel>
      </PanelGroup>
    );
  }
};

export const HeatmapConfigurationInner = memo(HeatmapConfigurationInnerImpl);
