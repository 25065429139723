import { assertDefined } from "./misc";

//https://create-react-app.dev/docs/adding-custom-environment-variables/

interface EnvVariables {
  REACT_APP_SUPPORT_EMAIL: string;
  REACT_APP_PRIVACY_POLICY_EMAIL: string;
  REACT_APP_DEVELOPER_NAME: string;
  REACT_APP_CHROME_EXTENSION_LINK: string;
  REACT_APP_GOOGLE_PLACES_DEV_AND_QA_KEY: string;
  REACT_APP_GOOGLE_PLACES_PROD_KEY: string;
  REACT_APP_GRAPHHOPPER_TESTING_API_KEY: string;
  REACT_APP_TARGOMO_TESTING_API_KEY: string;
  REACT_APP_PROD_FIREBASE_CONFIG: string;
  REACT_APP_PUBLIC_POSTHOG_DEV_KEY: string;
  REACT_APP_PUBLIC_POSTHOG_PROD_KEY: string;
  REACT_APP_PUBLIC_POSTHOG_HOST: string;
}

declare global {
  namespace NodeJS {
    interface ProcessEnv extends EnvVariables {}
  }
}

type ExplicitEnvVariables = {
  [K in keyof NodeJS.ProcessEnv as K extends string
    ? string extends K
      ? never
      : K
    : never]: NodeJS.ProcessEnv[K];
};

export function getEnvVariable<T extends keyof ExplicitEnvVariables>(
  key: T
): ExplicitEnvVariables[T] {
  return assertDefined(process.env[key]);
}
