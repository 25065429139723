import { Tooltip, type TooltipProps } from "@mantine/core";
import { useCallback, useEffect, useRef } from "react";
import type { Nullable } from "types/utils";
import { useBooleanState } from "utils/hooks";

interface TouchFriendlyTooltipProps extends TooltipProps {
  innerTooltipRef?: React.MutableRefObject<Nullable<HTMLSpanElement>>;
}

export const TouchFriendlyTooltip = (props: TouchFriendlyTooltipProps) => {
  const [isShowing, show, hide] = useBooleanState(false);
  const ref = useRef<HTMLSpanElement>();

  const { innerTooltipRef, ...otherProps } = props;

  const assignRefs = useCallback(
    (val: HTMLSpanElement) => {
      ref.current = val;
      if (props.innerTooltipRef) props.innerTooltipRef.current = val;
    },
    [props.innerTooltipRef, ref]
  );

  useEffect(() => {
    const callback = (e: TouchEvent) => {
      for (const touch of e.touches) {
        if (touch.target !== ref.current) {
          hide();
          return;
        }
      }
    };

    document.addEventListener("touchstart", callback);
    return () => document.removeEventListener("touchstart", callback);
  }, [hide]);

  useEffect(() => {
    // We only need this when innerTooltipRef is defined
    // since it can be activated by another component
    if (props.innerTooltipRef) {
      const callback = (e: MouseEvent) => {
        if (e.target !== ref.current) {
          hide();
          return;
        }
      };
      document.addEventListener("click", callback);
      return () => document.removeEventListener("click", callback);
    }
  }, [hide, props.innerTooltipRef]);

  return (
    <span
      ref={assignRefs}
      onFocus={show}
      onClick={show}
      onBlur={hide}
      onMouseMove={show}
      onMouseLeave={hide}
      onTouchStart={show}
    >
      <Tooltip {...otherProps} opened={isShowing} />
    </span>
  );
};
