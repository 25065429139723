//This is here just for reference
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const rawMapPointerSvg = `<?xml version="1.0" encoding="iso-8859-1"?>
<svg height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 512 512" xml:space="preserve">
<path style="fill:#ffffff;" d="M87.084,192c-0.456-5.272-0.688-10.6-0.688-16C86.404,78.8,162.34,0,256.004,0s169.6,78.8,169.6,176
	c0,5.392-0.232,10.728-0.688,16h0.688c0,96.184-169.6,320-169.6,320s-169.6-223.288-169.6-320H87.084z M256.004,224
	c36.392,1.024,66.744-27.608,67.84-64c-1.096-36.392-31.448-65.024-67.84-64c-36.392-1.024-66.744,27.608-67.84,64
	C189.26,196.392,219.612,225.024,256.004,224z"/>
</svg>`;

// https://iotools.cloud/tool/svg-to-base64-encode/
export const mapPointerSvgBase64 =
  "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8c3ZnIGhlaWdodD0iODAwcHgiIHdpZHRoPSI4MDBweCIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgCgkgdmlld0JveD0iMCAwIDUxMiA1MTIiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8cGF0aCBzdHlsZT0iZmlsbDojZmZmZmZmOyIgZD0iTTg3LjA4NCwxOTJjLTAuNDU2LTUuMjcyLTAuNjg4LTEwLjYtMC42ODgtMTZDODYuNDA0LDc4LjgsMTYyLjM0LDAsMjU2LjAwNCwwczE2OS42LDc4LjgsMTY5LjYsMTc2CgljMCw1LjM5Mi0wLjIzMiwxMC43MjgtMC42ODgsMTZoMC42ODhjMCw5Ni4xODQtMTY5LjYsMzIwLTE2OS42LDMyMHMtMTY5LjYtMjIzLjI4OC0xNjkuNi0zMjBIODcuMDg0eiBNMjU2LjAwNCwyMjQKCWMzNi4zOTIsMS4wMjQsNjYuNzQ0LTI3LjYwOCw2Ny44NC02NGMtMS4wOTYtMzYuMzkyLTMxLjQ0OC02NS4wMjQtNjcuODQtNjRjLTM2LjM5Mi0xLjAyNC02Ni43NDQsMjcuNjA4LTY3Ljg0LDY0CglDMTg5LjI2LDE5Ni4zOTIsMjE5LjYxMiwyMjUuMDI0LDI1Ni4wMDQsMjI0eiIvPgo8L3N2Zz4=";
