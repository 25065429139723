import { ColorPicker, ColorSwatch, Modal, Popover, Stack, Text, Tooltip } from "@mantine/core";
import { GetColorName } from "hex-color-to-color-name";
import { useSmallScreen } from "page_setup/ScreenSizeContext";
import { useMemo } from "react";
import { useBooleanState } from "utils/hooks";

interface ColorInputProps {
  color: string;
  onChange: (color: string) => void;
  tooltip?: string;
}

export const STANDARD_COLOR_SWATCHES = [
  "#25262b",
  "#868e96",
  "#fa5252",
  "#e64980",
  "#be4bdb",
  "#7950f2",
  "#4c6ef5",
  "#228be6",
  "#15aabf",
  "#12b886",
  "#40c057",
  "#82c91e",
  "#fab005",
  "#fd7e14",
];

export const CompactColorInput = (props: ColorInputProps) => {
  const { color, onChange, tooltip } = props;
  const [modalIsOpen, openModal, closeModal] = useBooleanState(false);

  const smallScreen = useSmallScreen();

  const colorName = useMemo(() => GetColorName(color.replace("#", "")), [color]);

  const mainDisplay = (
    <Tooltip label={tooltip} withArrow hidden={!tooltip}>
      <span className="p-0 m-0 flex">
        <ColorSwatch color={color} component="button" onClick={openModal} />
      </span>
    </Tooltip>
  );

  const editingDisplay = (
    <Stack>
      <Text size={"sm"}>{colorName}</Text>
      <ColorPicker
        value={color}
        size={smallScreen ? "lg" : "md"}
        swatches={STANDARD_COLOR_SWATCHES}
        onChange={onChange}
      />
    </Stack>
  );

  if (smallScreen) {
    return (
      <>
        {mainDisplay}
        <Modal.Root opened={modalIsOpen} onClose={closeModal}>
          <Modal.Overlay />
          <Modal.Content>
            <Modal.Header>
              <Modal.CloseButton />
            </Modal.Header>
            <Modal.Body>{editingDisplay}</Modal.Body>
          </Modal.Content>
        </Modal.Root>
      </>
    );
  } else {
    return (
      <Popover opened={modalIsOpen} onChange={closeModal}>
        <Popover.Target>{mainDisplay}</Popover.Target>
        <Popover.Dropdown>{editingDisplay}</Popover.Dropdown>
      </Popover>
    );
  }
};
