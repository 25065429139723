import { View } from "ol";
import type { FeatureLike } from "ol/Feature";
import type { Point } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import Map from "ol/Map";
import VectorSource from "ol/source/Vector";
import { useEffect, useRef } from "react";
import {
  getCommonControls,
  getCommonPointStyle,
  getCommonTileLayer,
  getCommonViewArgs,
  getInvisibleStyle,
  MAP_TARGET_ELEMENT_ID,
} from "utils/openLayers/commonMapParts";

export interface HeatmapPointChooserPoint {
  geometry: Point;
  name?: string;
  color?: string;
  hidden?: boolean;
}

export const usePointChooserMap = () => {
  const mapRef = useRef<Map>();
  const pointSourceRef = useRef<VectorSource>();

  useEffect(() => {
    const pointSource = new VectorSource({});
    pointSourceRef.current = pointSource;

    mapRef.current = new Map({
      target: MAP_TARGET_ELEMENT_ID,
      layers: [
        getCommonTileLayer(),
        new VectorLayer({
          source: pointSource,
          style: (feature: FeatureLike) => {
            const properties = feature.getProperties() as HeatmapPointChooserPoint;
            if (properties.hidden) return getInvisibleStyle();
            return getCommonPointStyle({ colorOverride: properties.color });
          },
        }),
      ],
      controls: getCommonControls(),
      view: new View(getCommonViewArgs()),
    });
  }, []);

  return { mapRef, pointSourceRef };
};
