import {
  AddressSpecificQuery,
  OpenQuery,
  type SerializedAddressSpecificQuery,
  type SerializedOpenQuery,
  type SerializedQuery,
  SerializedQueryType,
} from "pages/distance_matrix/locationQueryTypes";

const isOpenQuery = (serializedQuery: SerializedQuery): serializedQuery is SerializedOpenQuery => {
  return serializedQuery.type === SerializedQueryType.OPEN;
};

const deserializeOpenQuery = (serializedQuery: SerializedOpenQuery) => {
  return new OpenQuery(serializedQuery);
};

const isAddressQuery = (
  serializedQuery: SerializedQuery
): serializedQuery is SerializedAddressSpecificQuery => {
  return serializedQuery.type === SerializedQueryType.ADDRESS_SPECIFIC;
};

const deserializeAddressQuery = (serializedQuery: SerializedAddressSpecificQuery) => {
  return new AddressSpecificQuery(serializedQuery);
};

export const QueryDeserializer = {
  isOpenQuery,
  deserializeOpenQuery,
  isAddressQuery,
  deserializeAddressQuery,
};
