import { Accordion, Divider, List, Slider, Text, ThemeIcon, type SliderProps } from "@mantine/core";
import { memo, useCallback, useMemo } from "react";

import type { GroupID, Heatmap } from "types/internal_apis/get_heatmap";
import { assertDefined } from "utils/misc";

import { GroupHeader } from "common_ui/heatmap_specific/GroupHeader";
import { FiMapPin } from "react-icons/fi";
import type { WithRequired } from "types/utils";
import { transportationModeToString } from "utils/heatmap/transportationModes";
import { DEFAULT_GROUP_IMPORTANCE, MAX_GROUP_IMPORTANCE, MIN_GROUP_IMPORTANCE } from "./constants";
import type { GroupMetadata } from "types/heatmap_storage/storedHeatmap";

interface GroupListElementProps {
  heatmap: Heatmap;
  groupId: string;
  groupMetadata: WithRequired<GroupMetadata, "relativeImportance" | "color">;
  onImportanceChange: (groupId: GroupID, newImportance: number) => void;
  onChangeColor: (groupId: GroupID, color: string) => void;
  onToggleVisibility: (groupId: GroupID) => void;
}

const SLIDER_TICKS: SliderProps["marks"] = [
  { value: 0, label: "Ignore it" },
  { value: 4 },
  { value: 8 },
  { value: DEFAULT_GROUP_IMPORTANCE, label: "Default" },
  { value: 12 },
  { value: 16 },
  { value: MAX_GROUP_IMPORTANCE, label: "Super Important" },
];

export const GroupListElement = memo((props: GroupListElementProps) => {
  const { heatmap, groupMetadata, groupId, onImportanceChange, onChangeColor, onToggleVisibility } =
    props;

  const relevantPlaces = useMemo(() => {
    return Object.values(heatmap.places).filter((x) => x.group_id === groupId);
  }, [groupId, heatmap]);

  const group = useMemo(() => {
    return assertDefined(heatmap.groups[groupId as GroupID]);
  }, [groupId, heatmap.groups]);

  const onSliderChange = useCallback(
    (val: number) => {
      onImportanceChange(groupId as GroupID, val);
    },
    [groupId, onImportanceChange]
  );

  const onColorChange = useCallback(
    (color: string) => {
      onChangeColor(groupId as GroupID, color);
    },
    [groupId, onChangeColor]
  );

  const onToggleHide = useCallback(() => {
    onToggleVisibility(groupId as GroupID);
  }, [groupId, onToggleVisibility]);

  return (
    <div className="col pt-2 gap-2">
      <GroupHeader
        name={group.name}
        hidden={groupMetadata.hidePoints}
        color={groupMetadata.color}
        onColorChange={onColorChange}
        onToggleHide={onToggleHide}
      />

      <div className="col ml-2 gap-2">
        <Text c="dimmed">
          {`You want to be at most ${
            group.max_bucket_in_s / 60
          } minutes by ${transportationModeToString(group.transportation_mode)} from any of these ${
            relevantPlaces.length
          } places.`}
        </Text>

        <Accordion variant="filled" chevronPosition="left">
          <Accordion.Item value="places">
            <Accordion.Control>See Places</Accordion.Control>
            <Accordion.Panel>
              <List
                icon={
                  <ThemeIcon color="yellow" size={24} radius="xl">
                    <FiMapPin />
                  </ThemeIcon>
                }
                spacing={8}
              >
                {relevantPlaces.map((place) => (
                  <List.Item key={place.id}>{place.name}</List.Item>
                ))}
              </List>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        <Text>How important is this condition to you?</Text>
        <Slider
          min={MIN_GROUP_IMPORTANCE}
          max={MAX_GROUP_IMPORTANCE}
          marks={SLIDER_TICKS}
          value={groupMetadata.relativeImportance}
          onChange={onSliderChange}
        />
      </div>
      <div className="my-6">
        <Divider />
      </div>
    </div>
  );
});
