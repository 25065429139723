import {
  type ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
  type MantineThemeOverride,
} from "@mantine/core";
import type React from "react";
import { useMemo, useState } from "react";
import { getLightOrDarkMode, setLightOrDarkMode } from "storage/localStorageAccessors";

const componentsOverride: MantineThemeOverride["components"] = {
  Input: {
    styles: (theme, params, { variant }) => ({
      wrapper: {
        flex: 1,
      },
    }),
  },
  Autocomplete: {
    styles: (theme, params, { variant }) => ({
      root: {
        flex: 1,
      },
    }),
  },
  TextInput: {
    styles: (theme, params, { variant }) => ({
      root: {
        flex: 1,
      },
    }),
  },
};

// https://v6.mantine.dev/theming/mantine-provider/
export const ThemeProvider = (props: { children: React.ReactNode }) => {
  const [colorScheme, setColorScheme] = useState<ColorScheme>(getLightOrDarkMode());
  const toggleColorScheme = () => {
    const newMode = colorScheme === "dark" ? "light" : "dark";
    setColorScheme(newMode);
    setLightOrDarkMode(newMode);
  };

  const theme: MantineThemeOverride = useMemo(
    () => ({
      components: componentsOverride,
      colorScheme,
    }),
    [colorScheme]
  );

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider theme={theme} withNormalizeCSS withGlobalStyles withCSSVariables>
        {props.children}
      </MantineProvider>
    </ColorSchemeProvider>
  );
};
