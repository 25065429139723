import { Loader } from "@googlemaps/js-api-loader";
import { getPreferredModeOfTransport } from "storage/localStorageAccessors";
import { type Coordinates, TransportationMode, PublicTransportationMode } from "types/geo";
import type { Nullable, Undefined, Optional, PartialRecord } from "types/utils";
import { getEnvVariable } from "utils/env";
import { isInDevMode } from "utils/misc";
import { timedPromise, MEDIUM_TIMEOUT } from "utils/promises";

// TODO: a lot of the APIs here will be deprecated by March 2025. I'll need to migrate to their
// "Places (New)" counterparts
/** 
APIs in use here:
Autocomplete (autocompleteService): 
    https://developers.google.com/maps/documentation/places/web-service/autocomplete
    Pricing (based on how we use it): https://developers.google.com/maps/documentation/places/web-service/usage-and-billing#ac-with-details-session

Place Details (placeDetailsService.getDetails()): 
    https://developers.google.com/maps/documentation/places/web-service/details
    Pricing: https://developers.google.com/maps/documentation/places/web-service/usage-and-billing#places-details

Find Place (placeDetailsService.findPlaceFromQuery()):
    https://developers.google.com/maps/documentation/places/web-service/search-find-place
    Pricing: https://developers.google.com/maps/documentation/places/web-service/usage-and-billing#find-place

//TODO: Find Place only returns one place, consider replacing it with Nearby Search
https://developers.google.com/maps/documentation/places/web-service/search-nearby

Directions (directionsService): 
    https://developers.google.com/maps/documentation/directions/overview
    https://developers.google.com/maps/documentation/javascript/directions#Directions
    Pricing: https://developers.google.com/maps/documentation/directions/usage-and-billing

If you take a look at the pricing, you'll realize that these APIs are all ridiculously expensive.
Note that there's a new and old Places API (I think we use old). https://developers.google.com/maps/documentation/places/web-service/overview
There's also now a Routes API that's like a better version of the Directions API. https://developers.google.com/maps/documentation/routes/migrate-routes-why

Also note that Google place_ids are not permanent and have to be refreshed periodically.
See https://developers.google.com/maps/documentation/javascript/place-id#refresh-id

*/

export const getApiKey = () =>
  isInDevMode()
    ? getEnvVariable("REACT_APP_GOOGLE_PLACES_PROD_KEY")
    : getEnvVariable("REACT_APP_GOOGLE_PLACES_PROD_KEY");

const loader = new Loader({
  apiKey: getApiKey(),
  version: "weekly",
  libraries: ["places"],
});

// Google Places API
let placesLibrary: Nullable<google.maps.PlacesLibrary> = null;
let autocompleteService: Nullable<google.maps.places.AutocompleteService> = null;
let placeDetailsService: Nullable<google.maps.places.PlacesService> = null;
let sessionToken: Undefined<google.maps.places.AutocompleteSessionToken> = undefined;

// Google Directions API
// I believe google.maps.RoutesLibrary is a misnomer, it's using the Directions API and not the Routes API (to know the difference, see comment above)
let directionsLibrary: Nullable<google.maps.RoutesLibrary> = null;
let directionsService: Nullable<google.maps.DirectionsService> = null;

let isInitialized = false;
const autocompleteResponsesCache: Record<string, google.maps.places.AutocompletePrediction[]> = {};

export const initGoogleApi = async () => {
  if (isInitialized) return;
  isInitialized = true;

  placesLibrary = await loader.importLibrary("places");
  autocompleteService = new placesLibrary.AutocompleteService();
  const dummyDivElement = document.createElement("div");
  placeDetailsService = new placesLibrary.PlacesService(dummyDivElement);

  directionsLibrary = await loader.importLibrary("routes");
  directionsService = new directionsLibrary.DirectionsService();
};

export const requestAutocompleteFromGoogle = async (
  rawQuery: string,
  userCoordinates: Optional<Coordinates>
): Promise<Array<google.maps.places.AutocompletePrediction>> => {
  const query = rawQuery.trim();
  if (!query) {
    return [];
  }

  //Check the cache first
  if (autocompleteResponsesCache[query]) {
    return autocompleteResponsesCache[query];
  }

  if (!autocompleteService) throw new Error("Missing autocomplete service");
  if (!sessionToken) sessionToken = new google.maps.places.AutocompleteSessionToken();

  const request: google.maps.places.AutocompletionRequest = {
    input: rawQuery,
    sessionToken,
    locationBias: userCoordinates
      ? new google.maps.LatLngBounds(
          new google.maps.LatLng(userCoordinates.latitude, userCoordinates.longitude)
        )
      : "IP_BIAS",
  };
  const response = await timedPromise(
    autocompleteService?.getPlacePredictions(request),
    MEDIUM_TIMEOUT
  );
  autocompleteResponsesCache[query] = response.predictions;
  return response.predictions;
};

export const requestCompletePlaceDataFromGoogle = async (
  placeId: string
): Promise<Nullable<google.maps.places.PlaceResult>> => {
  if (!placeDetailsService) throw new Error("Missing places details service");

  const request: google.maps.places.PlaceDetailsRequest = {
    placeId: placeId,
    sessionToken,
    fields: ["geometry", "place_id"], //Important to limit the field you ask for (costs less money!)
  };

  const requestPromise = new Promise<Nullable<google.maps.places.PlaceResult>>((resolve) => {
    placeDetailsService!.getDetails(request, (result) => resolve(result));
  });

  const response = await timedPromise(requestPromise, MEDIUM_TIMEOUT);
  sessionToken = undefined;
  return response;
};

export const requestCompletePlaceDataFromGoogleFromQuery = async (
  query: string,
  referenceLocation: Optional<Coordinates>
): Promise<Nullable<google.maps.places.PlaceResult[]>> => {
  if (!placeDetailsService) throw new Error("Missing places details service");

  const request: google.maps.places.FindPlaceFromQueryRequest = {
    query,
    fields: ["name", "geometry", "place_id", "formatted_address"],
    locationBias: referenceLocation
      ? new google.maps.LatLngBounds(
          new google.maps.LatLng(referenceLocation.latitude, referenceLocation.longitude)
        )
      : "IP_BIAS",
  };

  const requestPromise = new Promise<Nullable<google.maps.places.PlaceResult[]>>((resolve) => {
    placeDetailsService!.findPlaceFromQuery(request, (result) => resolve(result));
  });

  const response = await timedPromise(requestPromise, MEDIUM_TIMEOUT);
  sessionToken = undefined;
  return response;
};

export const mapGoogleModeOfTransport = (mode: google.maps.TravelMode): TransportationMode => {
  const TRANSPORT_MODE_MAP = {
    [google.maps.TravelMode.BICYCLING]: TransportationMode.BIKING,
    [google.maps.TravelMode.DRIVING]: TransportationMode.DRIVING,
    [google.maps.TravelMode.TRANSIT]: TransportationMode.PUBLIC_TRANSPORTATION,
    [google.maps.TravelMode.WALKING]: TransportationMode.WALKING,
  };
  return TRANSPORT_MODE_MAP[mode];
};

export const mapGoogleModeOfTransportInverse = (
  mode: TransportationMode
): google.maps.TravelMode => {
  const TRANSPORT_MODE_MAP = {
    [TransportationMode.BIKING]: google.maps.TravelMode.BICYCLING,
    [TransportationMode.DRIVING]: google.maps.TravelMode.DRIVING,
    [TransportationMode.PUBLIC_TRANSPORTATION]: google.maps.TravelMode.TRANSIT,
    [TransportationMode.WALKING]: google.maps.TravelMode.WALKING,
  };
  return TRANSPORT_MODE_MAP[mode];
};

export const mapGooglePublicTransportationMode = (
  mode: google.maps.VehicleType
): PublicTransportationMode => {
  const TRANSPORT_MODE_MAP: PartialRecord<google.maps.VehicleType, PublicTransportationMode> = {
    [google.maps.VehicleType.SUBWAY]: PublicTransportationMode.SUBWAY,
    [google.maps.VehicleType.BUS]: PublicTransportationMode.BUS,
    [google.maps.VehicleType.INTERCITY_BUS]: PublicTransportationMode.BUS,
    [google.maps.VehicleType.COMMUTER_TRAIN]: PublicTransportationMode.TRAIN,
    [google.maps.VehicleType.MONORAIL]: PublicTransportationMode.TRAIN,
    [google.maps.VehicleType.RAIL]: PublicTransportationMode.TRAIN,
  };
  return TRANSPORT_MODE_MAP[mode] ?? PublicTransportationMode.OTHER;
};

export const requestDirectionsBetweenPlaces = async (
  origin: Coordinates,
  destinationFallbackCoords: Coordinates,
  destinationPlaceId?: string
): Promise<Nullable<google.maps.DirectionsResult>> => {
  if (!directionsService) throw new Error("Missing directions service");

  const request: google.maps.DirectionsRequest = {
    origin: new google.maps.LatLng(origin.latitude, origin.longitude),
    destination: destinationPlaceId
      ? { placeId: destinationPlaceId }
      : new google.maps.LatLng(
          destinationFallbackCoords.latitude,
          destinationFallbackCoords.longitude
        ),
    travelMode: mapGoogleModeOfTransportInverse(getPreferredModeOfTransport()),
  };

  const requestPromise = new Promise<Nullable<google.maps.DirectionsResult>>((resolve) => {
    directionsService!.route(request, (result) => resolve(result));
  });

  const response = await timedPromise(requestPromise, MEDIUM_TIMEOUT);
  sessionToken = undefined;
  return response;
};
