import type VectorSource from "ol/source/Vector";
import type Map from "ol/Map";
import type { Optional, Undefined } from "types/utils";
import type Feature from "ol/Feature";
import type Geometry from "ol/geom/Geometry";
import type { FeatureCollection, Feature as FeatureType } from "geojson";
import GeoJSON from "ol/format/GeoJSON";
import { OPEN_LAYERS_DEFAULT_MAP_PROJECTION } from "./OpenLayersConstants";

const MAP_PADDING_MULTIPLE_POINTS = 50;
const SINGLE_POINT_ZOOM = 15;

export function centerMapAroundVectorSource(source: Optional<VectorSource>, map: Optional<Map>) {
  if (!map || !source) return;
  if (source.isEmpty()) return;
  const numberOfPoints = source.getFeatures().length;
  if (numberOfPoints === 1) {
    map.getView().setCenter(source.getFeatures().at(0)?.getGeometry()?.getExtent());
    map.getView().setZoom(SINGLE_POINT_ZOOM);
  } else {
    map.getView().fit(source.getExtent(), {
      padding: [
        MAP_PADDING_MULTIPLE_POINTS,
        MAP_PADDING_MULTIPLE_POINTS,
        MAP_PADDING_MULTIPLE_POINTS,
        MAP_PADDING_MULTIPLE_POINTS,
      ],
    });
  }
}

export const addPolygonsToSource = (
  source: Undefined<VectorSource<Feature<Geometry>>>,
  features: FeatureType[] | FeatureCollection,
  projection?: Undefined<string>
) => {
  let featuresArray: FeatureType[] = [];
  if (Array.isArray(features)) {
    featuresArray = [...features];
  } else {
    featuresArray = features.features;
  }

  featuresArray.forEach((feature) => {
    const result = new GeoJSON().readFeature(feature, {
      dataProjection: projection,
      featureProjection: OPEN_LAYERS_DEFAULT_MAP_PROJECTION,
    });
    if (Array.isArray(result)) {
      source?.addFeatures(result);
    } else {
      source?.addFeature(result);
    }
  });
};
