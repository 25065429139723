import { Box, Container, Text } from "@mantine/core";
import { FiInfo } from "react-icons/fi";
import { botProtectEmail } from "utils/emails";
import { getEnvVariable } from "utils/env";
import { useBooleanState } from "utils/hooks";

export const WorkInProgressBanner = () => {
  return (
    <Box className="w-100 flex px-2" bg="gray" c="white">
      <Container>
        <FiInfo />{" "}
        <Text span>
          This project is is still in active development! Please share your feedback to{" "}
          <SupportEmailRedactor />
        </Text>
      </Container>
    </Box>
  );
};

const SupportEmailRedactor = () => {
  const [isShowing, setShowingTrue] = useBooleanState(false);

  return (
    <span onClick={setShowingTrue}>
      {isShowing ? (
        <Text span>{botProtectEmail(getEnvVariable("REACT_APP_SUPPORT_EMAIL"))}</Text>
      ) : (
        <Text span className="bg-black text text-neutral-200">
          [click to see email]
        </Text>
      )}
    </span>
  );
};
