import Color from "color";
import { DEFAULT_GROUP_PIN_COLOR } from "common_ui/heatmap_specific/constants";
import { interpolateRgbBasis } from "d3-interpolate";
import type { FeatureLike } from "ol/Feature";
import Fill from "ol/style/Fill";
import Style from "ol/style/Style";
import { DEFAULT_GROUP_IMPORTANCE } from "pages/heatmap_configuration/constants";
import { useRef, useCallback } from "react";
import type { GroupMetadata } from "types/heatmap_storage/storedHeatmap";
import type {
  Heatmap,
  GroupID,
  PartID,
  HeatmapPartProperties,
} from "types/internal_apis/get_heatmap";
import type { WithRequired } from "types/utils";
import { recordEntries, hasValue, assertDefined } from "utils/misc";
import { getInvisibleStyle } from "utils/openLayers/commonMapParts";

export function useHeatmapPolygonStyling(
  heatmapRef: React.MutableRefObject<Heatmap>,
  groupMetadataRef: React.MutableRefObject<Record<GroupID, GroupMetadata>>
) {
  const NON_EXISTENT_GROUP_PLACE_MULTIPLIER = 1.1;
  const HEATMAP_POLYGON_ALPHA = 0.55;

  const showPartsWithPartialMatchesRef = useRef(false);
  const onlyShowBestPartsRef = useRef(false);

  const partScoresRef = useRef<Record<PartID, number>>({});
  const minPartScoreRef = useRef<number>(0);
  const maxPartScoreRef = useRef<number>(0);

  const getGroupMetadata = useCallback(
    (groupId: GroupID): WithRequired<GroupMetadata, "relativeImportance" | "color"> => {
      const metadata = {
        ...groupMetadataRef.current[groupId],
        relativeImportance:
          groupMetadataRef.current[groupId]?.relativeImportance ?? DEFAULT_GROUP_IMPORTANCE,
        color: groupMetadataRef.current[groupId]?.color ?? DEFAULT_GROUP_PIN_COLOR,
      };
      return metadata;
    },
    [groupMetadataRef]
  );

  const refreshHeatmapPartScores = useCallback(() => {
    const scores: Record<PartID, number> = {};
    const heatmap = heatmapRef.current;

    for (const part of heatmap.feature_collection.features) {
      const partId = part.properties.id;
      const bucketsPerPlace = part.properties.buckets_per_place_group;
      let satisfiedAllGroups = true;
      recordEntries(bucketsPerPlace).forEach((entry) => {
        const groupId = entry[0];
        const groupBucket = entry[1];
        satisfiedAllGroups = satisfiedAllGroups && hasValue(groupBucket.bucket_in_s);
        const maxBucketInSeconds = assertDefined(heatmap.groups[groupId]).max_bucket_in_s;
        const bucketInSeconds =
          groupBucket.bucket_in_s ?? maxBucketInSeconds * NON_EXISTENT_GROUP_PLACE_MULTIPLIER;
        const groupImportance = getGroupMetadata(groupId).relativeImportance;
        if (scores[partId] === undefined) scores[partId] = 0;
        scores[partId] += (1 - bucketInSeconds / maxBucketInSeconds) * groupImportance;
      });

      if (!satisfiedAllGroups && !showPartsWithPartialMatchesRef.current) {
        delete scores[partId];
      }
    }

    const scoresExist = !!Object.keys(scores).length;
    partScoresRef.current = scores;
    minPartScoreRef.current = scoresExist ? Math.min(...Object.values(scores)) : 0;
    maxPartScoreRef.current = scoresExist ? Math.max(...Object.values(scores)) : 0;
  }, [getGroupMetadata, heatmapRef]);

  const polygonStyleFunction = useCallback(
    (feature: FeatureLike) => {
      const properties = feature.getProperties() as HeatmapPartProperties;

      const numberOfMatchedGroups = Object.values(properties.buckets_per_place_group).filter((x) =>
        hasValue(x.bucket_in_s)
      ).length;
      const numberOfGroups = Object.keys(heatmapRef.current.groups).length;

      if (numberOfGroups !== numberOfMatchedGroups && !showPartsWithPartialMatchesRef.current) {
        return getInvisibleStyle();
      }

      const rawScore = assertDefined(partScoresRef.current[properties.id]);
      let normalizedScore = 0;
      if (maxPartScoreRef.current > 0) {
        normalizedScore =
          (rawScore - minPartScoreRef.current) /
          Math.max(maxPartScoreRef.current - minPartScoreRef.current, 1);
      }

      const colorScale = interpolateRgbBasis(["red", "yellow", "green"]);
      let color = colorScale(normalizedScore);

      if (normalizedScore === 1) {
        color = onlyShowBestPartsRef.current ? "purple" : "darkgreen";
      } else if (onlyShowBestPartsRef.current) {
        return getInvisibleStyle();
      }

      return new Style({
        fill: new Fill({
          color: Color(color).alpha(HEATMAP_POLYGON_ALPHA).toString(),
        }),
      });
    },
    [heatmapRef]
  );
  return {
    refreshHeatmapPartScores,
    getGroupMetadata,
    polygonStyleFunction,
    showPartsWithPartialMatchesRef,
    onlyShowBestPartsRef,
  };
}
