import { makePage, TAB_NAME_BASE } from "page_setup/makePage";

import { Text } from "@mantine/core";
import { HeatmapConfigurationInner } from "pages/heatmap_configuration/HeatmapConfigurationInner";
import { useLocation } from "react-router-dom";
import type { Optional } from "types/utils";
import type { HeatmapConfigurationPageNavigationArgs } from "./types";

const HeatmapConfigurationImpl = () => {
  const location = useLocation();
  const locationState = location.state as Optional<HeatmapConfigurationPageNavigationArgs>;

  if (!locationState) {
    return <Text>No heatmap!</Text>;
  }

  return <HeatmapConfigurationInner heatmap={locationState} />;
};

export const HeatmapConfiguration = makePage(
  HeatmapConfigurationImpl,
  TAB_NAME_BASE + "Heatmap Viewer"
);
