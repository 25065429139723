import { Anchor, Container, Image, Text, Title } from "@mantine/core";
import { makePage } from "page_setup/makePage";
import { getEnvVariable } from "utils/env";

const BrowserExtensionPageImpl = () => {
  return (
    <Container size="md" p="md">
      <Title order={2} align="center" mb="lg">
        We have an extension!
      </Title>
      <Text>
        We have an extension to allow you to view your heatmaps directly in sites in Zillow and
        StreetEasy!
        <br />
        It's only supported in Chrome right now. 😭
        <br />
        We want to it to be supported in other browsers too! But they are missing some features that
        don't make it possible right now.
      </Text>
      <div className="p-2 flex row items-center justify-center w-100">
        <Anchor href={getEnvVariable("REACT_APP_CHROME_EXTENSION_LINK")} target="_blank">
          <img
            src={require("media/ChromeWebStoreButton.png")}
            height={60}
            alt="Extension Download Button"
          />
        </Anchor>
      </div>
      <div>
        <Image radius="md" src={require("media/WebExtensionScreenshot.png")} />
      </div>
    </Container>
  );
};

export const BrowserExtensionPage = makePage(BrowserExtensionPageImpl, "We have an extension!", {
  hideLocationUI: true,
});
