import {
  Box,
  Button,
  Card,
  Center,
  Container,
  type MantineNumberSize,
  Mark,
  SimpleGrid,
  Space,
  Stack,
  Text,
  Title,
} from "@mantine/core";

import { makePage } from "page_setup/makePage";
import { useSmallScreen } from "page_setup/ScreenSizeContext";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import "./LandingPage.css";
import TextLoop from "./TextLoop";
import { WorkInProgressBanner } from "./WorkInProgressBanner";
import { ChromeExtensionBadge } from "./BrowserExtensionBadge";

const LandingPageImpl = () => {
  const navigate = useNavigate();
  const smallScreen = useSmallScreen();
  const size: MantineNumberSize = smallScreen ? "lg" : "xl";

  const goToMatrix = useCallback(() => {
    navigate("/distance-matrix");
  }, [navigate]);

  const goToHeatmap = useCallback(() => {
    navigate("/heatmap/create");
  }, [navigate]);

  const goToSavedHeatmap = useCallback(() => {
    navigate("/heatmap/list");
  }, [navigate]);

  return (
    <div className="w100 h100 landing-page-parent">
      <div className="solid-landing-page-background" />
      <img
        className="landing-page-city-background"
        src={require("media/LandingPageBackground.jpg")}
        onLoad={(e) => (e.currentTarget.style.opacity = "1")}
        alt="City Landscape"
      />
      <span>
        <Text
          className="landing-page-background-attribution"
          component="a"
          href="https://www.vecteezy.com/free-vector/skyline"
          size="xs"
        >
          Skyline Vectors by Vecteezy
        </Text>
      </span>

      <WorkInProgressBanner />

      <Box className="w100 landing-page-header" c="black">
        <Container>
          <Title order={3}>There to Where</Title>
        </Container>
      </Box>

      <Container className="landing-page-main-content" c={"black"}>
        <Stack className="h100">
          <Title order={1}>
            <TextLoop>
              <Mark>Moving</Mark>
              <Mark>Booking that hotel</Mark>
              <Mark>Picking your Airbnb</Mark>
              <Mark>Apartment hunting</Mark>
            </TextLoop>
            <br />
            just became easier.
          </Title>

          <Text size={size}>
            There to Where helps you evaluate listings based on their proximity to things{" "}
            <Mark>you</Mark> care about.
            <br />
            No more juggling of Google Maps tabs.
            <br />
            No more "Arg! I forgot this is far from work" moments.
            <br />
            No more{" "}
            <Text italic span>
              frustration.
            </Text>
            <br />
          </Text>

          <Text size={size}>
            <Mark>Handle everything in one page.</Mark>
          </Text>

          <Text size={size}>
            Don't have a specific place in mind? No worries. We can help you determine the best
            places in your city to start looking.
          </Text>

          <SimpleGrid cols={smallScreen ? 1 : 2}>
            <Card shadow="sm" padding="lg" radius="md" withBorder>
              <Stack h={"100%"}>
                <Text fw={500}>
                  Wondering how far a listing is from work, your friends, that gym, maybe some chess
                  clubs, and your favorite grocery chain?
                </Text>
                <div className="flex-1" />
                <Button color={"grape"} onClick={goToMatrix}>
                  Check out our Distance Matrix
                </Button>
              </Stack>
            </Card>

            <Card shadow="sm" padding="lg" radius="md" withBorder>
              <Stack h={"100%"}>
                <Text fw={500}>
                  Wondering which parts of your city fit your preferences the most?
                </Text>
                <div className="flex-1" />
                <Button color={"grape"} onClick={goToHeatmap}>
                  Make a City Heatmap
                </Button>
                <Button color={"grape"} variant="outline" onClick={goToSavedHeatmap}>
                  See your Saved Heatmaps
                </Button>
              </Stack>
            </Card>
          </SimpleGrid>
        </Stack>

        <Space h="sm" />

        <Center>
          <ChromeExtensionBadge />
        </Center>
      </Container>
    </div>
  );
};

export const LandingPage = makePage(LandingPageImpl, undefined, { hideLocationUI: true });
