import { Button } from "@mantine/core";
import { Panel, PanelGroup } from "react-resizable-panels";

import { StandardPanelResizeHandle } from "common_ui/StandardPanelResizeHandle";
import { useCallback } from "react";
import { makePage } from "page_setup/makePage";
import type { GeographicPlace } from "types/geo";
import { useRenderVersion } from "utils/hooks";
import { useDevMap } from "./DevMap";
import PlacesAutocompleteText from "common_ui/PlaceAutocompleteTextInput";
import { PersistentMapAnchor } from "common_ui/openlayers_specific/PersistentMapAnchor";

// https://epsg.io/map sucks, so I made this
// This is mainly for making my own heatmap presets for testing...
const DevPointGetterImpl = () => {
  const [, triggerRerender] = useRenderVersion();
  const { getDevMapPoints, clearPoints, addPoints, mapRef } = useDevMap({
    onPointsChange: triggerRerender,
  });

  const onLocationChosen = useCallback(
    (s: GeographicPlace) => {
      addPoints([[s.coords.longitude, s.coords.latitude]]);
    },
    [addPoints]
  );

  return (
    <PanelGroup autoSaveId="devPointChooserPanelGroup" direction="horizontal">
      <Panel style={{ padding: 8 }}>
        <Button onClick={clearPoints}>Clear points</Button>
        <PlacesAutocompleteText onLocationChosen={onLocationChosen} />
        <p>Points (represented in [lng, lat] format...</p>
        <p>
          {getDevMapPoints()
            .map((x) => `[${x[0]}, ${x[1]}]`)
            .join(", \n")}
        </p>
      </Panel>

      <StandardPanelResizeHandle />

      <Panel maxSize={80} minSize={20}>
        <PersistentMapAnchor className="w-100 h-full" map={mapRef.current} />
      </Panel>
    </PanelGroup>
  );
};

export const DevPointGetter = makePage(DevPointGetterImpl, "<Dev Page>");
