import { memo, useEffect, useMemo, useRef } from "react";
import { Card, Text } from "@mantine/core";
import { Overlay, type Map } from "ol";
import type Feature from "ol/Feature";
import type { Point } from "ol/geom";
import type { Heatmap } from "types/internal_apis/get_heatmap";
import { assertDefined } from "utils/misc";
import { truncateStringOpt } from "utils/strings";
import type { HeatmapConfigurationPointProperties } from "./HeatmapConfigurationMap";

interface HeatmapPointOverlayProps {
  heatmap: Heatmap;
  point: Feature<Point>;
  map: Map;
}

const HeatmapPointOverlayImpl = (props: HeatmapPointOverlayProps) => {
  const { map, heatmap, point } = props;
  const ref = useRef<HTMLDivElement>(null);

  const placeName = useMemo(() => {
    const properties = point.getProperties() as HeatmapConfigurationPointProperties;
    return assertDefined(heatmap.places[properties.placeId]).name;
  }, [heatmap, point]);

  useEffect(() => {
    const overlay = new Overlay({
      element: ref.current ?? undefined,
    });
    const geometry = point.getGeometry();

    const coordinate = assertDefined(geometry?.getCoordinates());
    overlay.setPosition(assertDefined(coordinate));
    map.addOverlay(overlay);
    return () => {
      overlay.setMap(null);
    };
  }, [point, map]);

  // Wrapping this component in a div is important: the OpenLayers map is going to move
  // the reffed inner div, and without an outer div, the React virtual DOM will be messed up
  return (
    <div>
      <div ref={ref} className="heatmap-overlay">
        <Card shadow="sm" padding="sm" radius="md">
          <Text weight={"bold"}>Heatmap Point</Text>
          <Text>{truncateStringOpt(placeName, 30)}</Text>
        </Card>
      </div>
    </div>
  );
};

export const HeatmapPointOverlay = memo(HeatmapPointOverlayImpl);
