import { Alert, Modal, Title } from "@mantine/core";
import { FiCloudRain } from "react-icons/fi";
import { isInDevMode, noop } from "utils/misc";
import { useGlobalErrorState } from "./CrashHandlerContext";
import { useBooleanState } from "utils/hooks";
import { useEffect } from "react";

export const CrashHandlerNotice = () => {
  const errorState = useGlobalErrorState();
  const inDevMode = isInDevMode();
  const [hidden, hide, show] = useBooleanState(false);

  useEffect(() => {
    show();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorState.version]);

  const alert = (
    <Alert
      icon={<FiCloudRain size="1rem" />}
      title="Bummer!"
      color="red"
      withCloseButton={inDevMode}
      closeButtonLabel="Close (only possible on dev)"
      onClose={hide}
    >
      Looks like we encountered an unexpected Error! We'll look into this.
    </Alert>
  );

  if (errorState.message && inDevMode && !hidden) {
    return alert;
  }

  if (errorState.message) {
    return (
      <Modal.Root opened onClose={noop}>
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Title order={3}>Unknown Error!</Title>
          </Modal.Header>
          <Modal.Body>{alert}</Modal.Body>
        </Modal.Content>
      </Modal.Root>
    );
  }

  return null;
};
