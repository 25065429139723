import type React from "react";
import { memo } from "react";
import { LoadingIndicator } from "./LoadingIndicator";
import { useBooleanState } from "utils/hooks";

export const IframeContainer = memo((props: React.IframeHTMLAttributes<HTMLIFrameElement>) => {
  const [isLoading, , setLoadingFalse] = useBooleanState(true);

  return (
    <>
      {isLoading && (
        <div
          style={{
            height: props.height,
            width: props.width,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <LoadingIndicator shouldShow={isLoading} size={20} />
        </div>
      )}
      <iframe
        title="Dummy"
        {...props}
        onLoad={setLoadingFalse}
        style={{ display: isLoading ? "none" : "block" }}
      />
    </>
  );
});
